<template>
	<div>
		<div class="chartletForm">
			<el-form 
				:model="chartletForm" 
				:rules="rules" 
				ref="chartletForm" 
				label-width="100px"
			>
				<el-form-item label-width="130px" label="贴图名称" ref="title" prop="title">
					<el-input 
						class="w400"
						placeholder="请输入贴图名称" 
						v-model="chartletForm.title"
					></el-input>
				</el-form-item>
				
				
				<el-form-item label-width="130px" label="分类" required>
					<el-col
						:span="7"
						style="width: 1.9rem"
						v-if="menuList.length > 0"
						>
						<el-form-item prop="classify1">
							<el-select
								v-model="chartletForm.classify1"
								@change="selectClass"
								placeholder="请选择分类"
							>
								<el-option
									v-for="(item, index) in menuList"
									:key="index"
									:label="item.name"
									:value="item.id"
								></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col
						:span="1"
						class="segmentation"
						v-if="menuChildList.length > 0"
						>-</el-col>
					<el-col
						:span="7"
						style="width: 1.9rem"
						v-if="menuChildList.length > 0"
						>
						<el-form-item prop="classify2">
							<el-select
								v-model="chartletForm.classify2"
								placeholder="请选择分类"
								>
								<el-option
									v-for="(item, index) in menuChildList"
									:key="index"
									:label="item.name"
									:value="item.id"
								></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-form-item>
				
				<el-form-item label="贴图类型" label-width="130px">
					<el-checkbox 
						:true-label="1" 
						:false-label="0" 
						v-model="chartletForm.is_brand"
						>
						品牌贴图
					</el-checkbox>
					<el-checkbox 
						:true-label="1" 
						:false-label="0" 
						v-model="chartletForm.type"
						>
						无缝贴图
					</el-checkbox>
				</el-form-item>
				<el-form-item v-if="chartletForm.is_brand" label-width="130px" ref="brand" prop="brand">
					<el-input 
						class="w400"
						placeholder="请输入品牌名称" 
						v-model="chartletForm.brand"
					></el-input>
				</el-form-item>
				<el-form-item label="是否选择专辑" label-width="130px">
					<el-radio-group 
						v-model="chartletForm.is_album"
						@change="isAlbumFun"
						>
						<el-radio :label="1">有</el-radio>
						<el-radio :label="0">否</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item v-if="chartletForm.is_album" label-width="130px" ref="album_name" prop="album_name">
					<el-select
						class="w400"
						v-model="chartletForm.album_name"
						@change="selectAlbumFun"
						filterable
						allow-create
						default-first-option
						no-data-text="请填写专辑名称后选择"
						placeholder="选择或填写专辑名称">
						<el-option
							v-for="(item, index) in albumList"
							:key="index"
							:label="item.title"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="价格" label-width="130px" ref="is_free" prop="is_free">
					<el-radio-group v-model="chartletForm.is_free">
						<el-radio :label="0">免费</el-radio>
						<el-radio :label="1">0.1知币（0.1元）</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label-width="130px" label="上传贴图" ref="pic_str" prop="pic_str">
					<uploads-images ref="UploadsImages"
													:list="fileList"
													:listArrObj="fileObjLists"
													:multiple="true"
													:compress='true'
													:astrict="[400, 300]"
													:watermark="true"
													:fileMaxSize='10'
													:limit="20"
													@success="handleUploadSuccess"
													>
					</uploads-images>
					<div
						class="el-upload__tip f12 c_bf"
						style="line-height: 0.25rem"
					>
						提示：贴图需小于10M，格式为JPG/JPEG/PNG。
					</div>
					<div
						class="el-upload__tip f12 c_bf"
						style="line-height: 0.25rem"
					>
						按住Ctrl可多选，单次最多上传20张贴图。
					</div>
				</el-form-item>
				<el-form-item prop="redAgreement" class="agreement">
					<el-checkbox v-model="chartletForm.redAgreement">
						<p class="c_66 f12">
							我已阅读并同意<router-link
								:to="{ path: '/other/index', query: { type: 2 } }"
								target="_blank"
								><span class="c_f46">《知设网协议》</span></router-link
							>
						</p>
					</el-checkbox>
				</el-form-item>
				<el-form-item label-width="130px">
					<el-button :loading="submitLoading" type="primary" @click="submitForm()">提交审核</el-button>
				</el-form-item>
				
			</el-form>
		</div>
		
		<div class="fromBottom">
			<div>
				<div class="fromBottomTitle">
					<img src="@/assets/image/icon/zsw-icon.png" alt="" />
					<p>作品上传说明</p>
				</div>
				<div class="uploadSpecification">
					<p>1.请精准填写贴图标题，准确选择分类，可提高素材被检索到的概率；</p>
					<p>
						2.贴图过小/带水印贴图不符者，含有广告性质贴图审核不予通过；
					</p>
					<p>3.贴图格式支持 jpg、jpeg、png 等；</p>
					<p>
						4.知设网鼓励上传“原创素材”，若上传素材与本网站他人作品雷同，将作出下架处理；
					</p>
					<p>5.作品审核时间为48小时，节假日顺延;</p>
					<p>6.付费贴图的收益，50%归原作者所有;</p>
				</div>
			</div>
		</div>
		
	</div>
</template>
<script>
	import {
		pictureGetEditInfo,
		pictureAdd,
		getProNavList,
		pictureGetMyAlbum,
	} from "../../../Api";
	import UploadsImages from "@/components/Uploads/UploadsImages.vue"
	import {
		getToken
	} from "@/utils/auth";

	export default {
		name: "ChartletForm",
		data() {
			let validateAgreement = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("请选阅读并同意《知设网协议》"));
				} else {
					callback();
				}
			};
			return {
				id: '',
				token: "",
				submitLoading: false,
				
				fileList: [],	// 已上传图片地址
				fileObjList: [],
				fileObjLists: [],
				chartletForm: {
					title: "",	// 标题
					
					fid: "",	// 分类id
					classify1: "",	// 分类1
					classify2: "",	// 分类2
					cate_path: "",	// 分类路径 (英文逗号隔开)
					
					is_brand: 0,	// 是否品牌 0-否 1-是
					type: 0,	// 是否无缝 0-否 1-是
					brand: "",	// 品牌名称
					
					is_album: 0,	// 是否专辑 0-否 1-是
					album_id: "",	// 专辑id
					album_name: "",	// 专辑名称
					
					is_free: 0, // 是否付费 0-免费 1-付费
					price: "",	// 价格
					
					pic_str: null,	// 贴图
					pic: [],
					
					redAgreement: false,	// 知设网协议
				},
				
				albumList: [],	// 专辑列表
				menuList: [],	// 分类列表1
				menuChildList: [],	// 分类列表2
				
				rules: {
					title: [{
						required: true,
						message: "请输入贴图名称",
						trigger: "blur"
					}],
					classify1: [{
						required: true,
						message: "请选择分类",
						trigger: "change"
					}, ],
					classify2: [{
						required: true,
						message: "请选择分类",
						trigger: "change"
					}, ],
					brand: [{
						required: true,
						message: "请输入品牌名称",
						trigger: "blur"
					}, ],
					album_name: [{ 
						required: true, 
						message: "选择或填写专辑名称", 
						trigger: "change" ,
					},],
					is_free: [{ 
						required: true, 
						message: "请选择贴图价格", 
						trigger: "change" ,
					},],
					pic_str: [{
						required: true,
						message: "请上传贴图",
						trigger: "change"
					}, ],
					redAgreement: [{ validator: validateAgreement, trigger: "change" }],
				},
			};
		},
		components: {
			UploadsImages
		},
		computed: {
		},
		watch: {},
		created() {
      this.getMenu(2205)
			this.getPictureGetMyAlbum()
			this.token = getToken();
			const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
			if (query.id) {
				this.id = query.id;
				this.geiDetail()
			}
		},
		methods: {
			// 数据初始化
			initial() {
				this.chartletForm = {
					title: "",	// 标题
					fid: "",	// 分类id
					classify1: "",	// 分类1
					classify2: "",	// 分类2
					cate_path: "",	// 分类路径 (英文逗号隔开)
					is_brand: 0,	// 是否品牌 0-否 1-是
					type: 0,	// 是否无缝 0-否 1-是
					brand: "",	// 品牌名称
					is_album: 0,	// 是否专辑 0-否 1-是
					album_id: "",	// 专辑id
					album_name: "",	// 专辑名称
					is_free: 0, // 是否付费 0-免费 1-付费
					price: "",	// 价格
					pic_str: [],	// 贴图
					redAgreement: false,	// 知设网协议
				};
				
				this.submitLoading = false;
				this.fileList = [];	// 已上传图片地址
				this.fileObjList = [];
				this.fileObjLists= [];
				this.getPictureGetMyAlbum()
			},
			// 获取专辑名称
			getPictureGetMyAlbum() {
				pictureGetMyAlbum({token: getToken()}).then((res) => {
					this.albumList = res.data;
				})
			},
			//选中分类一
			selectClass(val) {
				this.getMenu(val);
			},
			//获取菜单分类
			getMenu(id, init) {
				const data = {
					id: id,
					token: getToken(),
				};
				getProNavList(data).then((res) => {
					if (id == 2205) {
						this.menuList = res.data;
					} else {
						if (init != "init" ) {
							this.chartletForm.classify2 = "";
						}
						this.menuChildList = res.data;
					}
				});
			},
			// 是否专辑 0-否 1-是
			isAlbumFun(data) {
				console.log(data);
			},
			// 选择专辑
			selectAlbumFun(data) {
				if (this.albumList.length) {
					let arr = this.albumList.filter((i) => i.id === data)
					if (arr.length) {
						this.chartletForm.album_name = arr[0].title;
						this.chartletForm.album_id = arr[0].id;
					} else {
						this.chartletForm.album_id = "";
					}
				}
				// console.log(data);
			},
			// 获取详情
			geiDetail() {
				pictureGetEditInfo({id: this.id, token: getToken()}).then((res)=>{
					// console.log('获取详情--->', res);
					if (res.data) {
						let detailData = res.data
						let fidPathArr = detailData.cate_path.split(',')
						detailData.classify1 = Number(fidPathArr[0])
						detailData.classify2 = Number(fidPathArr[1])
						this.fileObjList = [{
							original: detailData.pic_path,
							watermark: detailData.pic_str,
							astrict: detailData.pic_compression,
						}];
						this.fileObjLists = [{
							original: detailData.pic_path,
							watermark: detailData.pic_str,
							astrict: detailData.pic_compression,
						}];
						this.fileList = [detailData.pic_path]
						// fileObjList
						this.chartletForm = {...this.chartletForm, ...detailData};
					}
					this.$nextTick(()=> {
						this.getMenu(this.chartletForm.classify1, "init")
					})
					// console.log('处理详情数据--->', this.chartletForm)
				})
			},
			
			/* 图片上传 */
			handleUploadSuccess(list, listArrObj) {
				this.chartletForm.pic_str = list;
				this.fileObjList = listArrObj;
				// console.log('list----->', list);
				// if(listArrObj.original) {
					// console.log('listArrObj---裁切图-->', listArrObj[0].astrict);
					// console.log('listArrObj---原图-->', listArrObj[0].original);
					// console.log('listArrObj---水印图-->', listArrObj[0].watermark);
				// }
			},
			
			// 选择分类
			selectClassType(val) {
				console.log(val);
			},
			// 提交审核
			submitForm() {
				
				this.chartletForm.pic = [];
				this.fileObjList.forEach((item)=> {
					this.chartletForm.pic.push({
						pic_path: item.original,
						pic_str: item.watermark,
						pic_compression: item.astrict,
					})
				})
				// if (this.chartletForm.pic) {
				// 	console.log(this.chartletForm.pic, 'this.chartletForm.pic');
				// 	return
				// }
				this.$refs.chartletForm.validate((valid, object) => {
					if (valid) {
						let params = JSON.parse(JSON.stringify(this.chartletForm));
						if (params.is_album != 1) {
							params.album_id = "";
							params.album_name ="";
						}
						params.token = this.token;
						params.fid = params.classify2;
						params.cate_path = `${params.classify1},${params.classify2}`;
						params.price = params.is_free ? "0.1" : "0";
						delete params.classify1
						delete params.classify2
						delete params.redAgreement
						delete params.pic_str
						if (params.pic_compression) {
							delete params.pic_compression
						}
						if (params.pic_path) {
							delete params.pic_path
						}
						if (this.id) {
							params.id = this.id
							this.editData(params);
						} else{
							this.saveData(params);
						}
						
					} else {
						let flag = true;
						let obj1 = Object.keys(object)[0];
						for( let i of Object.keys(this.rules)) {
							if (Object.keys(object).indexOf(i) >= 0) {
								if(this.$refs[i]) {
									flag = false
									this.$refs[i].$el.scrollIntoView({
										behavior: "smooth",
										block: 'center'
									})
									break;
								}
							}
						}
						if (flag && obj1) {
							if (this.$refs[obj1]&&this.$refs[obj1][0]) {
								this.$refs[obj1][0].$el.scrollIntoView({
									behavior: "smooth",
									block: 'center',
								})
							}
						}
					}
				});
			},
			saveData(params) {
				this.submitLoading = true;
				pictureAdd(params).then((res)=>{
					if (res.data.error === 0) {
						this.submitLoading = false;
						this.$message.success("发布成功！等待平台审核");
						this.$router.push({
							path: "/upload/myUpload",
							query: { activeName: "chartlet", uploadType: 2 },
						});
						// this.initial()
					} else {
						this.$message.error("发布失败！请刷新页面重新填写");
					}
				}).catch((err)=>{
					this.submitLoading = false;
					console.log(err);
				})
			},
			editData(params) {
				this.submitLoading = true;
				pictureAdd(params).then((res)=>{
					if (res.data.error === 0) {
						this.submitLoading = false;
						this.$message.success("编辑成功！等待平台审核");
						this.$router.push({
							path: "/upload/myUpload",
							query: { activeName: "chartlet", uploadType: 2 },
						});
					} else {
						this.$message.error("编辑失败！请刷新页面重新填写");
					}
				}).catch((err)=>{
					this.submitLoading = false;
					console.log(err);
				})
			}
		},
	};
</script>
<style scoped>
	.hide-upload-btn>>>.el-upload--picture-card {
		display: none;
	}
	.line {
		text-align: center;
	}
	.w580 {
		width: 580px;
	}
	.noActive {
		background: #bfbfbf !important;
		border-color: #bfbfbf !important;
	}
	.uploadSet {
		flex: 1;
		font-size: 0.25rem;
		text-align: right;
		color: #666666;
	}
	.avatar-uploader-icon {
		font-size: 0.28rem;
		color: #8c939d;
		text-align: center;
	}
	.uploadSet i {
		margin: 0 0.05rem;
		cursor: pointer;
	}
	.statusBar {
		flex: 1;
	}
	.uploadStatus {
		width: 3rem;
		display: flex;
		align-items: center;
	}
	.uploadFileItem {
		width: 6.56rem;
		height: 0.4rem;
		display: flex;
		align-items: center;
		font-size: 0.14rem;
		color: #333333;
		justify-content: space-between;
		background: #ffffff;
		box-shadow: 0px 0px 0.06rem rgba(102, 102, 102, 0.2);
		border-radius: 0.04rem;
		padding: 0 0.2rem;
	}
	.fileName {
		display: flex;
		align-items: center;
		/*line-height: 0.2rem;*/
		width: 2rem;
		margin-right: 0.5rem;
	}
	
	.fileName i {
		color: #7a7dff;
		font-size: 0.18rem;
		margin-right: 0.08rem;
	}
	.uploadFile >>> .el-upload__tip {
		line-height: 0.18rem;
	}
	.chartletForm {
		width: 100%;
		padding: 0.2rem .7rem 0.4rem 0;
		background: #ffffff;
		border-radius: 0.04rem;
	}

	.segmentation {
		width: 0.2rem;
		text-align: center;
	}

	.chartletForm>>>.uploadTx {
		font-size: 0.12rem;
		color: #bfbfbf;
		padding: 0 0.1rem;
		top: 0.85rem;
		position: absolute;
		line-height: 0.2rem;
		width: 2rem;
	}

	.chartletForm>>>.el-radio__label {
		font-size: 00.14rem;
	}

	.chartletForm>>>.el-form-item {
		margin-bottom: 0.4rem;
	}

	.chartletForm>>>.el-form-item .el-form-item {
		margin-bottom: 0;
	}

	.chartletForm>>>.el-radio__input.is-checked+.el-radio__label {
		color: #f46600;
	}
	.chartletForm>>>.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #f46600;
	}
	.chartletForm>>>.el-checkbox__input.is-checked .el-checkbox__inner {
		border-color: #f46600;
		background-color: #f46600;
	}
	.chartletForm>>>.el-checkbox__input.is-focus .el-checkbox__inner {
		border-color: #f46600;
	}
	.chartletForm>>>.el-checkbox__inner:hover {
		border-color: #f46600;
	}
	.chartletForm>>>.el-radio__inner:hover{
		border-color: #f46600;
	}

	.chartletForm>>>.el-upload-list--picture-card .el-upload-list__item-thumbnail {
		object-fit: cover;
	}
	.chartletForm>>>.el-form-item__label {
		padding-right: 0.2rem;
	}
	.chartletForm>>>.upload-demo .el-button--primary {
		width: 4rem;
		font-size: 00.14rem;
	}

	.chartletForm>>>.upload-demo .el-upload-list {
		width: 6.55rem;
	}

	.chartletForm>>>.upload-demo .el-upload-list__item {
		box-shadow: 0px 0px 0.06rem rgb(102 102 102 / 20%);
		height: 0.4rem;
		line-height: 0.4rem;
	}

	.chartletForm>>>.el-radio__input.is-checked .el-radio__inner {
		border-color: #f46600;
		background: #f46600;
	}

	.chartletForm>>>.el-button--primary {
		width: 1.55rem;
		background-color: #f46600;
		border-color: #f46600;
		font-size: 00.14rem;
	}

	.chartletForm>>>.el-button+.el-button {
		font-size: 0.14rem;
	}

	.chartletForm>>>.el-input {
		font-size: 0.14rem;
	}

	.chartletForm>>>.el-input__inner {
		height: 0.4rem;
		line-height: 0.4rem;
	}

	.chartletForm>>>.el-icon-plus {
		font-size: 28px;
		color: #8c939d;
	}

	.w900>>>.edui-default .edui-editor {
		z-index: 1 !important;
	}

	.avatar {
		width: 2rem;
	}

	.qrCodeUpload {
		width: 2rem;
		height: 2rem;
	}
	#editor_qukvxyhm {
		width: 9rem !important;
	}

	.ueditor>>>.el-form-item__content {
		line-height: 0;
	}
	
	.fromBottomTitle {
		display: flex;
		align-items: center;
	}

	.fromBottomTitle img {
		width: 0.16rem;
		height: 0.19rem;
		margin-right: 0.1rem;
	}

	.fromBottomTitle p {
		font-size: 0.16rem;
		color: #040404;
		font-weight: bold;
	}

	.fromBottom {
		width: 100%;
		margin-top: 0.1rem;
		margin-bottom: 0.3rem;
		padding: 0.2rem 0.3rem 0.4rem;
		background: #ffffff;
		border-radius: 0.04rem;
	}
	.uploadSpecification {
		margin-top: 0.2rem;
		margin-left: 0.25rem;
	}

	.uploadSpecification p {
		color: #666666;
		font-size: 0.14rem;
		margin-bottom: 0.25rem;
	}
</style>
