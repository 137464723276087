<!--单节课程上传表单组件-->
<template>
    <div>
        <div class="suForm" v-if="joinStatus">
            <el-form
                    :model="courseSingleForm"
                    :rules="rules"
                    ref="courseSingleForm"
                    label-width="100px"
                    class="demo-ruleForm"
            >
                <el-form-item label="上传封面" prop="cover">
                    <el-upload
                            class="avatar-uploader"
                            :show-file-list="false"
                            :auto-upload="true"
                            :action="$ROOT_DIR + '/comms/Release/uploadPics?token=' + token"
                            accept="image/png,image/jpg,image/jpeg"
                            :on-success="handleAvatarSuccess1"
                            :before-upload="beforeAvatarUpload"
                    >
                        <img
                                v-if="courseSingleForm.cover"
                                :src="courseSingleForm.cover"
                                class="avatar"
                        />
                        <i v-else class="el-icon-plus"></i>
                        <div
                                class="el-upload__text uploadTx"
                                v-if="!courseSingleForm.cover"
                        >
                            请上传课程封面
                        </div>
                        <div
                                class="el-upload__tip f12 c_bf"
                                slot="tip"
                                style="line-height: 0.25rem"
                        >
                            提示：封面尺寸比例为5:3，小于2M；建议像素为1000*600，格式为JPG/JPEG/PNG。
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="标题" prop="title">
                    <el-input
                            class="w400"
                            placeholder="请输入作品名称"
                            v-model="courseSingleForm.title"
                    ></el-input>
                </el-form-item>
                <el-form-item label="上传视频" prop="videos" required>
                    <CourseSingleUpload
                            :uploadTypeInfo="uploadVideo"
                            :courseSingleForm="courseSingleForm"
                            @uploadRes="uploadVideoRes"
                            @cancelRes="cancelVideo"
                            formType="video"
                    ></CourseSingleUpload>
                </el-form-item>
                <el-form-item label="视频价格" class="priceFormBox">
                    <div class="df_Yc w400">
                        <el-radio-group v-model="courseSingleForm.free" class="mr30">
                            <el-radio label="0">免费</el-radio>
                            <el-radio label="1">付费</el-radio>
                        </el-radio-group>
                        <el-form-item
                                prop="price"
                                style="flex: 1"
                                v-if="courseSingleForm.free == '1'"
                        >
                            <el-input
                                    placeholder="请输入视频价格"
                                    v-model.number="courseSingleForm.price"
                            ></el-input>
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item label="配套资源" prop="resources">
                    <CourseSingleUpload
                            :uploadTypeInfo="uploadResource"
                            :courseSingleForm="courseSingleForm"
                            @uploadRes="uploadResourceRes"
                            @cancelRes="cancelResource"
                            formType="resource"
                    ></CourseSingleUpload>
                </el-form-item>
                <el-form-item
                        label="配套资源价格"
                        class="priceFormBox"
                        label-width="130px"
                        v-show="courseSingleForm.free == 0"
                >
                    <div class="df_Yc w400">
                        <el-radio-group
                                v-model="courseSingleForm.resourcesFree"
                                class="mr30"
                        >
                            <el-radio label="1">免费</el-radio>
                            <el-radio label="2">付费</el-radio>
                        </el-radio-group>
                        <el-form-item
                                prop="resourcesPrice"
                                style="flex: 1"
                                v-if="courseSingleForm.resourcesFree == '2'"
                        >
                            <el-input
                                    placeholder="请输入配套资源价格"
                                    v-model.number="courseSingleForm.resourcesPrice"
                            ></el-input>
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item label="分类" required>
                    <el-col :span="7" style="width: 1.9rem" v-if="menuList.length > 0">
                        <el-form-item prop="classify1">
                            <el-select
                                    v-model="courseSingleForm.classify1"
                                    placeholder="请选择分类"
                                    @change="selectClass_1"
                            >
                                <el-option
                                        v-for="(item, index) in menuList"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" class="segmentation" v-if="menuChildList.length > 0"
                    >-
                    </el-col
                    >
                    <el-col
                            :span="7"
                            style="width: 1.9rem"
                            v-if="menuChildList.length > 0"
                    >
                        <el-form-item prop="classify2">
                            <el-select
                                    v-model="courseSingleForm.classify2"
                                    placeholder="请选择分类"
                            >
                                <el-option
                                        v-for="(item, index) in menuChildList"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item label="课程推荐" prop="recommendType">
                    <el-radio-group v-model="courseSingleForm.recommendType">
                        <el-radio
                                v-for="item in attrList"
                                :key="item.value"
                                :label="item.value"
                        >{{ item.name }}
                        </el-radio
                        >
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="辅导群" prop="coachGulp">
                    <el-radio-group v-model="courseSingleForm.coachGulp">
                        <el-radio label="1">有</el-radio>
                        <el-radio label="0">无</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="二维码" v-if="courseSingleForm.coachGulp == 1">
                    <el-upload
                            class="qrCodeUpload"
                            :action="$ROOT_DIR + '/comms/Release/uploadPics?token=' + token"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                    >
                        <img
                                v-if="courseSingleForm.qrCode"
                                :src="courseSingleForm.qrCode"
                                style="width: 2rem; height: 2rem"
                        />
                        <i v-else class="el-icon-plus"></i>
                        <div
                                class="el-upload__text uploadTx"
                                v-if="!courseSingleForm.qrCode"
                        >
                            请上传辅导群二维码
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="关联老师" prop="teacher">
                    <div style="border:1px solid lightgray;height:0.5rem;line-height:0.5rem;padding-left:0.08rem;overflow:hidden" class="w400"
                         @click="handleSelect">
                        <div v-if="courseSingleForm.teacher">{{translateName(courseSingleForm.teacher).join(',')}}</div>
                        <div v-else></div>
                    </div>
                </el-form-item>
                <el-form-item label="课程详情" prop="particulars" class="w900 ueditor">
                    <VueUeditorWrap
                            v-model="courseSingleForm.particulars"
                            :config="editorConfig"
                    ></VueUeditorWrap>
                </el-form-item>
                <el-form-item prop="redAgreement" class="agreement">
                    <el-checkbox v-model="courseSingleForm.redAgreement">
                        <p class="c_66 f12">
                            我已阅读并同意
                            <router-link
                                    :to="{ path: '/other/index', query: { type: 2 } }"
                                    target="_blank"
                            ><span class="c_f46">《知设网协议》</span></router-link
                            >
                        </p>
                    </el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('courseSingleForm')"
                    >提交审核
                    </el-button
                    >
                </el-form-item>
            </el-form>
        </div>
        <JoinForm v-else></JoinForm>
        <div class="fromBottom">
            <div>
                <div class="fromBottomTitle">
                    <img src="@/assets/image/icon/zsw-icon.png" alt=""/>
                    <p>作品上传说明</p>
                </div>
                <div class="uploadSpecification">
                    <p>1.请精准填写课程标题，准确选择分类，可提高课程被检索到的概率；</p>
                    <p>2.上传封面尺寸5:3比例，一定要漂亮哟，像素≥500*300；</p>
                    <p>
                        3.课程详情介绍，务必图文配合，对课程做详细的介绍，否则不予通过；
                    </p>
                    <p>
                        4.配套课程资源文件为 rar 、 zip 、7z等格式的压缩包，大小不超过1GB；
                    </p>
                    <p>
                        5.如果配套课程资源文件网盘链接，确保网盘链接有效,如发现网盘链接失效，所获收益将无法提现；
                    </p>
                    <p>
                        6.知设网鼓励上传“原创视频”，若上传视频与本网站他人作品雷同，将作出下架处理；
                    </p>
                    <p>
                        7.课程的意向价格为(0-10000)元；根据课程质量，知设网有权修改价格到合适区间；
                    </p>
                    <p>8.作品审核时间为48小时，节假日顺延;</p>
                </div>
            </div>
            <div>
                <div class="fromBottomTitle">
                    <img src="@/assets/image/icon/zsw-icon.png" alt=""/>
                    <p>作品收益说明</p>
                </div>
                <div class="uploadSpecification">
                    <p>
                        1.
                        “原创资料”的单独赞赏收益，其中50%归上传者所有，收益达到100元即可提现。
                    </p>
                </div>
            </div>
        </div>
        <el-dialog
                @close="closeSelectTeacher"
                title="请选择关联老师"
                :visible.sync="selectTeacherVb"
                width="8rem">
            <div class="selectBox">
                <div class="leftSelectBox">
                    <div>
                        <el-input v-model="selectTeacherParams.name"></el-input>
                        <i class="el-icon-search" style="margin-right:0.12rem;font-size:0.24rem" @click="searchKey"></i>
                    </div>
                    <div>
                        <el-checkbox-group v-model="selectTeacherParams.choosed" class="bottomBox">
                            <template v-if="selectTeacherParams.searchList.length>0">
                                <el-checkbox v-for="item in selectTeacherParams.searchList" :label="item.id"
                                             :key="item.id">{{item.name}}
                                </el-checkbox>
                            </template>
                            <div v-else>
                                <el-empty description="没有内容哦"></el-empty>
                            </div>
<!--                            <template v-else>-->
<!--                                <el-checkbox v-for="item in selectTeacherParams.oldList" :label="item.id"-->
<!--                                             :key="item.id">{{item.name}}-->
<!--                                </el-checkbox>-->
<!--                            </template>-->
                        </el-checkbox-group>
                    </div>
                </div>
                <div class="rightSelectBox">
                    <div style="font-size: 0.16rem;font-weight: 500;color: rgba(0, 0, 0, 1);">
                        已选：{{selectTeacherParams.choosed.length}}老师
                    </div>
                    <el-checkbox-group v-model="selectTeacherParams.choosed" class="bottomBox">
                        <template v-for="item in selectTeacherParams.oldList">
                            <el-checkbox v-if="selectTeacherParams.choosed.indexOf(item.id) !== -1" :label="item.id"
                                         :key="item.id">{{item.name}}
                            </el-checkbox>
                        </template>
                    </el-checkbox-group>
                </div>
            </div>
            <span slot="footer">
                <el-button type="info" @click="closeSelectTeacher">取消</el-button>
                <el-button type="info" size="large" style="background: rgba(244, 102, 0, 1);color:white"
                           @click="sureAddTeacher">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import CourseSingleUpload from "./CourseSingleUpload";
    import JoinForm from "./JoinForm";
    import VueUeditorWrap from "vue-ueditor-wrap";
    import {
        addVideo,
        getProNavList,
        checkJoin,
        classEdit,
        editVideo, getTeacherList, getClassAttr,
    } from "../../../Api";
    import {getToken} from "@/utils/auth";

    export default {
        name: "CourseSingleForm",
        components: {
            CourseSingleUpload,
            VueUeditorWrap,
            JoinForm,
        },
        data() {
            let validateAgreement = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error("请选阅读并同意《知设网协议》"));
                } else {
                    callback();
                }
            };
            return {
                selectTeacherParams: {
                    name: '',
                    oldList: [],
                    searchList: [],
                    choosed: []
                },
                selectTeacherVb: false,
                joinStatus: true,
                token: "",
                coverFile: null, //封面文件
                //上传视屏
                uploadVideo: {
                    type: "video",
                    isMulti: false,
                },
                //上传配套资源
                uploadResource: {
                    type: "resource",
                    isMulti: false,
                },
                courseSingleForm: {
                    cover: "",
                    videos: "",
                    resources: "",
                    resourcesName: "",
                    title: "",
                    classify1: "",
                    classify2: "",
                    recommendType: "",
                    coachGulp: "",
                    price: "",
                    particulars: "",
                    free: "0",
                    redAgreement: false,
                    //辅导群二维码
                    qrCode: "",
                    resourcesFree: "1",
                    resourcesPrice: "",
                    editId: "",
                    teacher: ''
                },
                rules: {
                    teacher: [
                        {required: true, message: "请选择老师", trigger: "change"},
                    ],
                    cover: [
                        {required: true, message: "请上传封面图片", trigger: "change"},
                    ],
                    videos: [
                        {required: true, message: "请上传课程视频", trigger: "change"},
                    ],
                    // resources: [
                    //   { required: true, message: "请上传配套资源", trigger: "change" },
                    // ],
                    title: [{required: true, message: "请输入标题", trigger: "blur"}],
                    classify1: [
                        {required: true, message: "请选择分类", trigger: "change"},
                    ],
                    classify2: [
                        {required: true, message: "请选择分类", trigger: "change"},
                    ],
                    recommendType: [
                        {required: true, message: "请选择模型推荐", trigger: "change"},
                    ],
                    coachGulp: [
                        {required: true, message: "请选择辅导群", trigger: "change"},
                    ],
                    price: [
                        {required: true, message: "请选择视频价格", trigger: "blur"},
                        {type: "number", message: "价格必须为数字值", trigger: "change"},
                    ],
                    resourcePrice: [
                        {required: true, message: "请选择配套资源价格", trigger: "blur"},
                        {type: "number", message: "价格必须为数字值", trigger: "change"},
                    ],
                    particulars: [
                        {required: true, message: "请编辑课程详情", trigger: "blur"},
                    ],
                    redAgreement: [{validator: validateAgreement, trigger: "change"}],
                },

                editorConfig: {
                    toolbars: [[
                        // 'fullscreen',
                        'source', '|', 'undo', 'redo', '|',
                        'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
                        'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
                        'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
                        'directionalityltr', 'directionalityrtl', 'indent', '|',
                        'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
                        'link', 'unlink', 'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
                        'simpleupload', 'insertimage', 'emotion', 'scrawl', 'insertvideo', 'music', 'attachment', 'map', 'gmap', 'insertframe', 'insertcode', 'webapp', 'pagebreak', 'template', 'background', '|',
                        'horizontal', 'date', 'time', 'spechars', 'snapscreen', 'wordimage', '|',
                        'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
                        'print', 'preview', 'searchreplace', 'drafts', 'help'
                    ]],
                    // 编辑器不自动被内容撑高
                    autoFloatEnabled: false,
                    // 初始容器高度
                    initialFrameHeight: 300,
                    // 初始容器宽度
                    initialFrameWidth: "120%",
                    // 调试完毕打包上线则切换回/static/UEditor/php/controller.php即可，不用做其他处理
                    serverUrl: "https://www.zhishew.com/edu/ueditor/php/controller.php",
                    UEDITOR_HOME_URL: "/UEditor/",
                },
                menuList: [],
                menuChildList: [],
                attrList: [],
                loadingInstance: null,
                editForm: "",
            };
        },
        created() {
            const params = {
                token: getToken(),
                page: 1,
                per_page: 1000
            }
            getTeacherList(params).then(res => {
                if (res.status === '200') {
                    this.selectTeacherParams.oldList = res.data.data
                    this.selectTeacherParams.searchList = res.data.data
                }
            })
            //判断是否签约
            checkJoin({token: getToken()}).then((res) => {
                console.log(res.data.message);
                if (res.data.message != "checkSuccess") {
                    this.joinStatus = false;
                }
            });
            this.getMenu(6);
            this.getAttr();
            this.token = getToken();
            if (this.$route.query.id) {
                this.courseSingleForm.editId = this.$route.query.id;
                classEdit({pid: this.$route.query.id, token: this.token}).then(
                    (res) => {

                        this.editForm = res.data;
                        //处理图片
                        this.courseSingleForm.teacher = this.editForm.teacher
                        // console.log(this.editForm)
                        this.selectTeacherParams.choosed = (this.editForm.teacher.length == 0 ? [] : this.editForm.teacher.split(',')).map(Number)
                        this.courseSingleForm.cover = this.editForm.cover;
                        //标题
                        this.courseSingleForm.title = this.editForm.title;
                        //价格
                        this.courseSingleForm.free = this.editForm.free.toString();
                        this.courseSingleForm.price = parseInt(this.editForm.moneyStr) || "";
                        //资源价格
                        this.courseSingleForm.resourcesPrice =
                            parseInt(this.editForm.single_video_info.attachment_price) || "";
                        this.courseSingleForm.resourcesFree =
                            this.editForm.single_video_info.attachment_charge.toString();
                        //富文本
                        this.courseSingleForm.particulars = this.editForm.content;
                        //推荐
                        this.courseSingleForm.recommendType = this.editForm.attdef;
                        //分类
                        this.courseSingleForm.classify1 = parseInt(
                            this.editForm.fidPath.split(",")[3]
                        );
                        this.courseSingleForm.classify2 = parseInt(
                            this.editForm.fidPath.split(",")[4]
                        );
                        //获取选中的分类菜单
                        this.getMenu1(this.courseSingleForm.classify1);
                        //是否有辅导群
                        if (this.editForm.groupCode) {
                            this.courseSingleForm.coachGulp = "1";
                            //辅导群二维码
                            this.courseSingleForm.qrCode = this.editForm.groupCode;
                        } else {
                            this.courseSingleForm.coachGulp = "0";
                        }
                        //文件
                        this.courseSingleForm.videos =
                            this.editForm.single_video_info.video_url;
                        this.courseSingleForm.resourcesName = this.editForm.fileName;
                        this.courseSingleForm.resources = this.editForm.filePath;
                    }
                );
            }
        },
        methods: {
            closeSelectTeacher() {
                this.selectTeacherVb = false
                this.selectTeacherParams.choosed = []
            },
            translateName(ids) {
                let names = []
                let idArr = ids.split(',')
                idArr.forEach(iitem => {
                    this.selectTeacherParams.oldList.forEach(item => {
                        // console.log(item.id,iitem)
                        if (item.id === Number(iitem)) names.push(item.name)
                    })
                })
                return names
            },
            sureAddTeacher() {
                if(this.selectTeacherParams.choosed.length === 0){
                    this.$message.warning('必须关联老师')
                    return
                }
                this.courseSingleForm.teacher = (this.selectTeacherParams.choosed.length === 0 ? [] : this.selectTeacherParams.choosed.join(','))
                this.selectTeacherVb = false
            },
            searchKey() {
                if (!this.selectTeacherParams.name) {
                    this.selectTeacherParams.searchList = this.selectTeacherParams.oldList
                    return
                }
                this.selectTeacherParams.searchList = []
                this.selectTeacherParams.oldList.forEach(item => {
                    // console.log(item.name.index)
                    if (item.name.indexOf(this.selectTeacherParams.name) != -1) {
                        this.selectTeacherParams.searchList.push(item)
                    }
                })
            },
            handleSelect() {
                // console.log(this.courseSingleForm.teacher)
                this.selectTeacherParams.choosed = (this.courseSingleForm.teacher.length == 0 ? [] : this.courseSingleForm.teacher.split(',')).map(Number)
                // console.log(this.selectTeacherParams.choosed)
                this.selectTeacherVb = true
            },
            //上传成功
            handleAvatarSuccess1(res) {
                if (res.data.message == "uploadSuccess") {
                    this.courseSingleForm.cover = res.data.filePath;
                }
            },
            //上传图片前的图片验证回调
            beforeAvatarUpload(file) {
                const _this = this;
                const isSize = new Promise(function (resolve, reject) {
                    const img = new Image();
                    const _URL = window.URL || window.webkitURl;
                    img.onload = function () {
                        file.width = img.width; //图片宽度
                        file.height = img.height; //图片高度
                        const valid = img.width >= 500 && img.height >= 300; //上传图片尺寸判定
                        const valid1 = (img.height / img.width).toFixed(2) == 0.6;
                        const valid2 = file.size / 1024 < 2048;
                        valid && valid1 && valid2 ? resolve() : reject(new Error("error"));
                    };
                    img.src = _URL.createObjectURL(file);
                }).then(
                    () => {
                        return file;
                    },
                    () => {
                        _this.$message.error(
                            '图片 "比例或大小" 不对，请重新上传'
                        );
                        return Promise.reject(new Error("error"));
                    }
                );
                return isSize;
            },
            //上传成功
            handleAvatarSuccess(res) {
                if (res.data.message == "uploadSuccess") {
                    this.courseSingleForm.qrCode = res.data.filePath;
                }
            },
            //获取菜单分类
            getMenu(id) {
                const data = {
                    id: id,
                    token: getToken(),
                };
                getProNavList(data).then((res) => {
                    if (id == 6) {
                        this.menuList = res.data;
                    } else {
                        this.courseSingleForm.classify2 = "";
                        this.menuChildList = res.data;
                    }
                });
            },
            //获取菜单分类
            getMenu1(id) {
                const data = {
                    id: id,
                    token: getToken(),
                };
                getProNavList(data).then((res) => {
                    if (id == 6) {
                        this.menuList = res.data;
                    } else {
                        this.menuChildList = res.data;
                    }
                });
            },
            //获取作品可选属性列表
            getAttr() {
                // getAttr(getToken()).then((res) => {
                //     this.attrList = [];
                //     res.data[2].name = "基础";
                //     res.data[0].name = "实战";
                //     res.data[1].name = "其他";
                //     this.attrList.push(res.data[2]);
                //     this.attrList.push(res.data[0]);
                //     this.attrList.push(res.data[1]);
                // });
                getClassAttr().then(res=>{
                    if(res.status == 200){
                        this.attrList = res.data
                    }
                })
            },
            //选中分类一
            selectClass_1(val) {
                this.getMenu(val);
            },
            //上传视屏成功回调
            uploadVideoRes(data) {
                this.courseSingleForm.videos = data.filePath;
            },
            //取消、删除视屏上传
            cancelVideo() {
                this.courseSingleForm.videos = "";
            },
            //上传资源
            uploadResourceRes(data) {
                this.courseSingleForm.resourcesName = data.fileOriginalName;
                this.courseSingleForm.resources = data.filePath;
            },
            //删除资源
            cancelResource() {
                this.courseSingleForm.resources = "";
            },
            imgPreview(file) {
                this.coverFile = file;
                let fileName = file.name;
                let regex = /(.jpg|.jpeg|.png)$/;
                if (regex.test(fileName.toLowerCase())) {
                    this.courseSingleForm.cover = URL.createObjectURL(file.raw);
                } else {
                    this.$message.error("请选择图片文件");
                }
            },
            submitForm(formName) {
                this.$refs[formName].validateField('teacher')
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.saveData();
                    }
                });
            },
            saveData() {
                const fidPath = [
                    this.courseSingleForm.classify1,
                    this.courseSingleForm.classify2,
                ].join(",");
                const data = {
                    teacher:this.courseSingleForm.teacher,
                    add_type: "single",
                    attdef: this.courseSingleForm.recommendType,
                    coachGulp: this.courseSingleForm.coachGulp,
                    cover: this.courseSingleForm.cover,
                    fidPath: fidPath,
                    fileName: this.courseSingleForm.resourcesName,
                    filePath: this.courseSingleForm.resources,
                    free: this.courseSingleForm.free,
                    isUnlock: 0,
                    moneyStr:
                        this.courseSingleForm.free == 0 ? 0 : this.courseSingleForm.price,
                    summary: this.courseSingleForm.title,
                    title: this.courseSingleForm.title,
                    token: getToken(),
                    video_cover: "",
                    video_url: this.courseSingleForm.videos,
                    zcontent: this.courseSingleForm.particulars,
                    groupCode: this.courseSingleForm.qrCode,
                    attachment_charge: this.courseSingleForm.resourcesFree,
                    attachment_price:
                        this.courseSingleForm.resourcesFree == 1
                            ? 0
                            : this.courseSingleForm.resourcesPrice,
                };
                if (!this.courseSingleForm.editId) {
                    addVideo(data).then((res) => {
                        if (res.data.error === 0 && res.data.message === "Success") {
                            this.$message.success("发布成功！等待平台审核");
                            this.$router.push({
                                path: "/upload/myUpload",
                                query: {activeName: "course", uploadType: 2},
                            });
                        } else {
                            this.$message.error("发布失败！请刷新页面重新填写");
                        }
                    });
                } else {
                    data.pid = this.courseSingleForm.editId;
                    editVideo(data).then((res) => {
                        if (res.data.error === 0 && res.data.message === "Success") {
                            this.$message.success("编辑成功！等待平台审核");
                            this.$router.push({
                                path: "/upload/myUpload",
                                query: {activeName: "course", uploadType: 2},
                            });
                        } else {
                            this.$message.error("编辑失败！请刷新页面重新填写");
                        }
                    });
                }

                this.loadingInstance.close();
            },
        },
    };
</script>
<style scoped>
    .agreement >>> .el-form-item__content {
        line-height: 0.1rem !important;
    }

    .priceFormBox {
        display: flex;
        align-items: center;
    }

    .priceFormBox >>> .el-form-item__content {
        margin-left: 0 !important;
    }

    .uploadSpecification {
        margin-top: 0.2rem;
        margin-left: 0.25rem;
    }

    .uploadSpecification p {
        color: #666666;
        font-size: 0.14rem;
        margin-bottom: 0.25rem;
    }

    .fromBottomTitle {
        display: flex;
        align-items: center;
    }

    .fromBottomTitle img {
        width: 0.16rem;
        height: 0.19rem;
        margin-right: 0.1rem;
    }

    .fromBottomTitle p {
        font-size: 0.16rem;
        color: #040404;
        font-weight: bold;
    }

    .fromBottom {
        width: 100%;
        margin-top: 0.1rem;
        margin-bottom: 0.3rem;
        padding: 0.2rem 0.3rem 0.4rem;
        background: #ffffff;
        border-radius: 0.04rem;
    }

    .suForm {
        width: 100%;
        margin-top: 0.1rem;
        padding: 0.2rem 0 0.4rem;
        background: #ffffff;
        border-radius: 0.04rem;
    }

    .segmentation {
        width: 0.2rem;
        text-align: center;
    }

    .suForm >>> .uploadTx {
        font-size: 0.12rem;
        color: #bfbfbf;
        padding: 0 0.1rem;
        top: 0.85rem;
        position: absolute;
        line-height: 0.2rem;
        width: 2rem;
    }

    .suForm >>> .el-radio__label {
        font-size: 00.14rem;
    }

    .suForm >>> .el-form-item {
        margin-bottom: 0.4rem;
    }

    .suForm >>> .el-form-item .el-form-item {
        margin-bottom: 0;
    }

    .suForm >>> .el-radio__input.is-checked + .el-radio__label {
        color: #f46600;
    }

    .suForm >>> .upload-demo .el-upload {
        border: 0;
        line-height: 40px;
        height: 40px;
        width: 4rem;
    }

    .suForm >>> .upload-demo .el-button--primary {
        width: 4rem;
        font-size: 00.14rem;
    }

    .suForm >>> .upload-demo .el-upload-list {
        width: 6.55rem;
    }

    .suForm >>> .upload-demo .el-upload-list__item {
        box-shadow: 0px 0px 0.06rem rgb(102 102 102 / 20%);
        height: 0.4rem;
        line-height: 0.4rem;
    }

    .suForm >>> .el-upload {
        width: 2rem;
        height: 1.2rem;
        background-color: #fff;
        border-radius: 0.06rem;
        box-sizing: border-box;
        text-align: center;
        position: relative;
        overflow: hidden;
        line-height: 1.2rem;
        background: #f7f6fb;
        border: none !important;
    }

    .suForm >>> .el-radio__input.is-checked .el-radio__inner {
        border-color: #f46600;
        background: #f46600;
    }

    .suForm >>> .el-button--primary {
        width: 1.55rem;
        background-color: #f46600;
        border-color: #f46600;
        font-size: 00.14rem;
    }

    .suForm >>> .el-button + .el-button {
        font-size: 0.14rem;
    }

    .suForm >>> .el-input {
        font-size: 0.14rem;
    }

    .suForm >>> .el-input__inner {
        height: 0.4rem;
        line-height: 0.4rem;
    }

    .suForm >>> .el-icon-plus {
        font-size: 28px;
        color: #8c939d;
    }

    .w900 >>> .edui-default .edui-editor {
        z-index: 1 !important;
    }

    .avatar {
        width: 2rem;
    }

    .qrCodeUpload {
        width: 2rem;
        height: 2rem;
    }

    .qrCodeUpload >>> .el-upload {
        width: 2rem;
        height: 2rem;
    }
    #editor_qukvxyhm{
        width: 9rem !important;
    }
    .ueditor >>> .el-form-item__content {
        line-height: 0;
    }

    .selectBox {
        /*width: 7rem;*/
        display: flex;
        height: 4rem;
        border: 1px solid rgba(128, 143, 167, 0.11);
    }

    .leftSelectBox {
        width: 50%;
        border-right: 1px solid rgba(128, 143, 167, 0.11)
    }

    .leftSelectBox > div:first-child {
        width: 90%;
        display: flex;
        border: 1px solid lightgray;
        border-radius: 0.18rem;
        align-items: center;
        margin-top: 0.22rem;
        margin-left: 5%
    }

    .rightSelectBox {
        width: 2.5rem;
        margin-top: 0.22rem;
        margin-left: 5%
    }

    .leftSelectBox >>> .el-input__inner {
        border-radius: 0.18rem;
        border: none
    }

    .bottomBox {
        width: 90%;
        margin: auto;
        margin-top: 0.12rem;
    }

    /*.bottomBox >>> .el-checkbox{*/
    /*  width:40%*/
    /*}*/
    .bottomBox >>> .el-checkbox__label {
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
        /*white-space: nowrap;*/
        /*width: 1rem*/
    }

    .bottomBox >>> .el-checkbox {
        /*width: 1.5rem;*/
        /*  border:1px solid red;*/
        margin-bottom: 0.16rem;
    }

    .bottomBox >>> .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background: rgba(244, 102, 0, 1);
        border:rgba(244, 102, 0, 1)
    }

    .bottomBox >>> .el-checkbox__input.is-checked + .el-checkbox__label {
        color: black
    }
</style>
