<template>
	<div>
		<div class="cos-box" :style="{'width':width,'height':height}">
			<div class="title t-color-9" :class="{'color-red':isRequired}" v-if="title">{{isRequired?title+' *':title}}
			</div>
			<div class="img-box" v-for="(img, index) in imgList" :key="index">
				<img class="img-box-thumbnail" :src="img" alt="">
				<template v-if="watermark">
					<span class="img-box-check" v-if="thumbnail? imgListArrObj&&imgListArrObj[index]&&imgListArrObj[index].succeed==4 : imgListArrObj&&imgListArrObj[index]&&imgListArrObj[index].succeed == 3">
						<i class="el-icon-check"></i>
					</span>
				</template>
				<template v-else>
					<span class="img-box-check">
						<i class="el-icon-check"></i>
					</span>
				</template>
				<span class="img-box-actions">
					<span class="img-box-icon img-box-preview">
						<i class="el-icon-zoom-in pointer" @click="showDialog(img)"></i>
					</span>
					<span class="img-box-icon img-box-delete">
						<i class="el-icon-delete pointer" @click="handleDel(img,index)"></i>
					</span>
				</span>
			</div>
			<!-- input 的id重复了 导致vue里面的this指向总是指向第一id的节点  ！！！重点！！！ -->
			<label class="add-box" :for="'cosFile'+index" v-if="maxLimitNum!==0 || limit===-1">
				<i class="el-icon-plus"></i>
				<input type="file" name="file" class="el-upload__input" :id="'cosFile'+index"
					@change="handleChange($event)" :multiple="multiple" accept="image/png,image/jpg,image/jpeg">
			</label>
		</div>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		qiniuUpload
	} from "@/utils/upload-img";
	var tindex = 0;
	export default {
		name: "UploadsImages",
		props: {
			title: { // 标题
				type: String,
				default: ''
			},
			width: { // 宽度
				type: String,
				default: '100%'
			},
			height: { // 宽度
				type: String,
				default: 'auto'
			},
			list: { //文件列表
				type: Array,
				default () {
					return []
				}
			},
			listArrObj: { //文件列表
				type: Array,
				default () {
					return []
				}
			},
			multiple: { // 是否可以多选
				type: Boolean,
				default: false
			},
			limit: { // 上传个数(-1为不限制上传个数)
				type: Number,
				default: -1
			},
			fileMaxSize: { // 图片大小(-1为不限制图片大小)
				type: Number,
				default: -1
			},
			isRequired: { // 是否必填
				type: Boolean,
				default: false
			},
			savePath: { // 保存路径文件名
				type: String,
				default: ''
			},
			compress: { // 是否进行图片压缩
				type: Boolean,
				default: false
			},
			astrict: { // 对压缩的图片限制宽高[宽度， 高度]	例：[400, 300]或者[400]
				type: Array,
				default () {
					return null
				}
			},
			watermark: { // 是否添加水印
				type: Boolean,
				default: false
			},
			thumbnail: { // 同时上传普通图
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				imgList: [],
				imgListArrObj: [],
				cos: null,
				maxLimitNum: null,
				needDelImg: [], //需要删除的图片
				index: 0,
			}
		},
		computed: {
			...mapGetters(["uploadTokenInfo"]),
		},
		watch: {
			listArrObj() {
				if(this.listArrObj.length) {
					let arr = []
					this.listArrObj.forEach((item)=> {
						if (this.thumbnail) {
							item.succeed=4
							arr.push(item)
						} else{
							item.succeed=3
							arr.push(item)
						}
					})
					this.imgListArrObj = arr
				}
			},
			list() {
				this.imgList = this.list
				this.maxLimitNum = this.limit - this.list.length
			},
		},
		created() {
			this.index = tindex; //用this.index 记录当前是第几次初始化组件 this.index 每次初始化会变为0 所以要赋值一下
			tindex += 1; //tindex 记录组件一共被初始化了多少次     全局变量不会被重置
			this.maxLimitNum = this.limit - this.list.length
			this.imgList = this.list
			if(this.listArrObj.length) {
				let arr = []
				this.listArrObj.forEach((item)=> {
					if (this.thumbnail) {
						item.succeed=4
					} else{
						item.succeed=3
					}
				})
				this.imgListArrObj = arr
			}
		},
		methods: {
			// 查看大图
			showDialog(img) {
				this.dialogImageUrl = img;
				this.dialogVisible = true
			},
			// 选择图片
			handleChange(e) {
				let files = [...e.target.files];
				if (this.limit > 0) {
					let maxLimitNum = Math.min(this.maxLimitNum, files.length);
					files = files.slice(0, maxLimitNum);
				}
				files.forEach(item => {
					this.handleUpload(item)
				})
			},
			// 上传前预处理判断
			handleUpload(e) {
				let fileSize = e.size / 1024;
				let fileMaxSize = 1024 * this.fileMaxSize; //判断图片大小
				if (fileSize > fileMaxSize && this.fileMaxSize > 0) {
					this.$message.error(
						'图片大小不对，请重新上传'
					);
					return
				}
				this.percent = 0;

				// 判断是否需要压缩
				if (this.compress) {
					let imgName = new Date().getTime() + Math.floor(Math.random() * 1000)
					// 需要添加水印
					if (this.watermark) {
						this.compressImg(e, 'watermark').then((res) => {
							this.qiniuUploadFn(res, imgName, 'watermark')
						}).catch(e => {
							console.log('e-compressImg-watermark->', e);
						})
					}
					// 同时上传普通图
					if (this.thumbnail) {
						this.compressImg(e, 'thumbnail').then((res) => {
							this.qiniuUploadFn(res, imgName, 'thumbnail')
						}).catch(e => {
							console.log('e-compressImg-thumbnail->', e);
						})
					}

					// 需要对压缩的图片限制宽高
					if (this.astrict) {
						this.compressImg(e, 'astrict').then((res) => {
							this.qiniuUploadFn(res, imgName, 'astrict')
						}).catch(e => {
							console.log('e-compressImg-astrict->', e);
						})
					}
					// 上传一份未压缩的原图
					this.qiniuUploadFn(e, imgName)
				} else {
					this.qiniuUploadFn(e)
				}
			},
			// 图片压缩
			compressImg(file, ask) {
				let disposeFile = file;
				if (Object.prototype.toString.call(file) === '[object Blob]') {
					disposeFile = new File([file], file.name, {
						type: file.type
					});
				}
				const read = new FileReader(),
					_that = this;
				const fileSize = parseFloat(parseInt(disposeFile['size']) / 1024 / 1024).toFixed(2);
				// 小于1m不压缩
				// if (fileSize < 1) return file;
				read.readAsDataURL(disposeFile);
				return new Promise((resolve, reject) => {
					try {
						read.onload = (e) => {
							const wmConfig = {
								font: "microsoft yahei", //字体
								textArray: ['知设网', 'www.zhishew.com'], //水印文本内容，允许数组最大长度3 即：3行水印
								density: 4 //密度 建议取值范围1-5 值越大，水印越多，可能会导致水印重叠等问题，慎重！！！
							}
							const img = new Image();
							img.src = e.target.result;
							img.onload = function() {
								let sx = 0,
									sy = 0,
									sWidth = Math.floor(this.width),
									sHeight = Math.floor(this.height),
									dWidth = Math.floor(this.width),
									dHeight = Math.floor(this.height);
									
								if (ask == 'thumbnail' && _that.thumbnail) {
									dWidth = dWidth / 2,
									dHeight = dHeight / 2;
								}
								if (dWidth > dHeight && dWidth > 3000) {
									let img_width = 3000;
									dHeight = Math.floor(img_width * dHeight / dWidth);
									dWidth = img_width;
								} else if (dHeight > dWidth && dHeight > 3000) {
									let img_height = 3000;
									dWidth = Math.floor(img_height * dWidth / dHeight);
									dHeight = img_height;
								}
								// 判定是否设置压缩的图片宽高
								if (ask == 'astrict' && _that.astrict && _that.astrict.length) {
									if (_that.astrict[1]) {
										// 确定画布宽高
										dWidth = _that.astrict[0];
										dHeight = _that.astrict[1];
										// 计算剪切图片位置
										sx = dWidth / dHeight < sWidth / sHeight ? Math.floor((sWidth -
											sHeight * dWidth / dHeight) / 2) : 0;
										sy = dWidth / dHeight > sWidth / sHeight ? Math.floor((sHeight -
											sWidth * dHeight / dWidth) / 2) : 0;
										// 计算剪切图片大小
										sWidth = dWidth / dHeight < sWidth / sHeight ? Math.floor(sHeight *
											dWidth / dHeight) : sWidth;
										sHeight = dWidth / dHeight > sWidth / sHeight ? Math.floor(sWidth *
											dHeight / dWidth) : sHeight;
									} else {
										dWidth = _that.astrict[0];
										dHeight = Math.floor(dWidth / sWidth * sHeight);
									}
								}
								
								const canvas = document.createElement('canvas');
								const ctx = canvas.getContext('2d');
								let base64;
								canvas.setAttribute('width', dWidth);
								canvas.setAttribute('height', dHeight);
								// console.log(sx, sy, sWidth, sHeight, 0, 0, dWidth, dHeight, 'ask--->', ask);
								
								ctx.drawImage(this, sx, sy, sWidth, sHeight, 0, 0, dWidth, dHeight);
								ctx.translate(0.5, -0.5)
								if (ask == 'watermark' && _that.watermark) {
									
									
									let img_w = (dWidth*0.3);
									if (dWidth/dHeight>1) {
										img_w = img_w/(dWidth/dHeight)
									}
									let img_h = Math.floor(img_w / 180 * 50);
									let bw = dWidth - img_w - 10,
										bh = dHeight - img_h - 10;
									let image = new Image(); //创建image对象
									image.src = require('../../assets/image/zsw_shuiyin666.png')
									img.setAttribute("crossOrigin", 'Anonymous')
									image.onload = function() {
										ctx.globalAlpha = 0.7;
										ctx.drawImage(image, bw, bh, img_w, img_h)
										
										// 判定是否设置压缩的图片宽高
										if (ask == 'watermark' && _that.watermark) {
											_that.drawWaterMark(ctx, dWidth, dHeight, wmConfig, this);
										}
										if (fileSize < 1) {
											// 如果图片小于一兆 那么不执行压缩操作
											// console.log('disposeFile---->', disposeFile);
											base64 = canvas.toDataURL(disposeFile['type'], 1);
										} else if (fileSize > 1 && fileSize < 2) {
											// 如果图片大于1M并且小于2M 那么压缩0.8
											base64 = canvas.toDataURL(disposeFile['type'], 1);
										} else {
											// 如果图片超过2m 那么压缩0.5
											base64 = canvas.toDataURL(disposeFile['type'], 1);
										}
										let disposeFileNameArr = disposeFile.name.split('.')
										let randow = '' + new Date().getTime() + Math.floor(Math
											.random() * 1000) + '.' + disposeFileNameArr[
											disposeFileNameArr.length - 1]
										resolve(_that.dataURLtoFile(base64, randow));
									}
								} else {
									if (fileSize < 1) {
										// 如果图片小于一兆 那么不执行压缩操作
										base64 = canvas.toDataURL(disposeFile['type'], 1);
									} else if (fileSize > 1 && fileSize < 2) {
										// 如果图片大于1M并且小于2M 那么压缩0.8
										base64 = canvas.toDataURL(disposeFile['type'], 0.8);
									} else {
										// 如果图片超过2m 那么压缩0.5
										base64 = canvas.toDataURL(disposeFile['type'], 0.5);
									}
									let disposeFileNameArr = disposeFile.name.split('.')
									let randow = '' + new Date().getTime() + Math.floor(Math.random() *
										1000) + '.' + disposeFileNameArr[disposeFileNameArr.length - 1]
									resolve(_that.dataURLtoFile(base64, randow));
								}
							};
						};
					} catch (error) {
						reject(disposeFile);
					}
				});
			},
			// 将base64编码转回file文件
			dataURLtoFile(dataurl, fileName) {
				var arr = dataurl.split(','),
					mime = arr[0].match(/:(.*?);/)[1],
					bstr = atob(arr[1]),
					n = bstr.length,
					u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}
				return new File([u8arr], fileName, {
					type: mime,
				});
			},
			// 获取图片平均颜色img, imgWidth, imgHeight
			getAverageRGB(imgEl, imgWidth, imgHeight) {
					let blockSize = 5, // only visit every 5 pixels
							defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
							canvas = document.createElement('canvas'),
							context = canvas.getContext && canvas.getContext('2d'),
							data, width, height,
							i = -4,
							length,
							rgb = {r:0,g:0,b:0},
							count = 0;
			
					if (!context) {
							return defaultRGB;
					}
			
					height = canvas.height = imgHeight;
					width = canvas.width = imgWidth;
			
					context.drawImage(imgEl, 0, 0);
			
					try {
							data = context.getImageData(0, 0, width, height);
					} catch(e) {
							/* security error, img on diff domain */
							return defaultRGB;
					}
			
					length = data.data.length;
			
					while ( (i += blockSize * 4) < length ) {
							++count;
							rgb.r += data.data[i];
							rgb.g += data.data[i+1];
							rgb.b += data.data[i+2];
					}
			
					// ~~ used to floor values
					rgb.r = ~~(rgb.r/count);
					rgb.g = ~~(rgb.g/count);
					rgb.b = ~~(rgb.b/count);
					// return rgb;	// 图片颜色
					
					console.log( rgb.r, rgb.g, rgb.b, '******');
					let r = 255 - rgb.r
					let g = 255 - rgb.g
					let b = 255 - rgb.b
					console.log('rgb('+r+','+g+','+b+')', '-----');
					// return 'rgb('+rgb.r+','+rgb.g+','+rgb.b+', 0.3)';	// 图片反色
					return 'rgb('+r+','+g+','+b+', 0.5)';	// 图片反色
			},
			
			// 添加文字水印
			drawWaterMark(ctx, imgWidth, imgHeight, wmConfig) {
				let fontSize;
				fontSize = Math.floor((imgWidth/45+imgHeight/45)/2)
				
				ctx.fillStyle = "white";
				ctx.font = `${
				fontSize}px ${
				wmConfig.font}`;
				ctx.lineWidth = 1;
				let gradient = ctx.createLinearGradient(0, 0, imgWidth, 0);
				gradient.addColorStop("0", "rgba(255, 255, 255, 0.5)");
				gradient.addColorStop("0.5", "rgba(255, 255, 255, 0.7)");
				gradient.addColorStop("1.0", "rgba(255, 255, 255, 0.5)");
				ctx.fillStyle = gradient;
				// ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
				// ctx.fillStyle = this.getAverageRGB(img, imgWidth, imgHeight);
				ctx.textAlign = "left";
				ctx.textBaseline = "middle";

				//文字坐标
				const maxPx = Math.max(imgWidth, imgHeight);
				const stepPx = Math.floor(maxPx / wmConfig.density);
				let arrayX = [0]; //初始水印位置 canvas坐标 0 0 点
				while (arrayX[arrayX.length - 1] < maxPx / 2) {
					arrayX.push(arrayX[arrayX.length - 1] + stepPx);
				}
				arrayX.push(...arrayX.slice(1, arrayX.length).map((el) => {
					return -el;
				}));
				for (let i = 0; i < arrayX.length; i++) {
					for (let j = 0; j < arrayX.length; j++) {
						ctx.save();
						ctx.translate(imgWidth / 2, imgHeight / 2); ///画布旋转原点 移到 图片中心
						ctx.rotate(-Math.PI / 5);
						if (wmConfig.textArray.length > 3) {
							wmConfig.textArray = wmConfig.textArray.slice(0, 3);
						}
						wmConfig.textArray.forEach((el, index) => {
							let offsetY = fontSize * index + 2;
							ctx.fillText(el, arrayX[i], arrayX[j] + offsetY);
						});
						ctx.restore();
					}
				}
			},
			// 上传至七牛云
			qiniuUploadFn(file, imgName, ask) {
				qiniuUpload(file, this.uploadTokenInfo.uptoken).then((res) => {
					const observable = res;
					observable.subscribe({
						next: (result) => {
							this.percent = Math.floor(result.total.percent)
						},
						error: (errResult) => {
							this.$message.error("上传失败,请刷新页面后重试。");
							console.log(errResult);
						},
						complete: (result) => {
							if (imgName) {
								// 添加原始数据
								let f = this.imgListArrObj.some(i => i.name == imgName)
								if (!f) {
									this.imgListArrObj.push({
										name: imgName,
										succeed: 0,
									})
								}
								if (!ask) {
									this.imgList.push(this.uploadTokenInfo.domain + result.key)
									this.imgListArrObj.forEach((item, index) => {
										if (imgName == item.name) {
											this.imgListArrObj[index].original = this
												.uploadTokenInfo.domain + result.key;
											this.imgListArrObj[index].succeed++;
										}
									})
								}
								// 添加水印
								if (ask && 'watermark' == ask) {
									this.imgListArrObj.forEach((item, index) => {
										if (imgName == item.name) {
											this.imgListArrObj[index].watermark = this
												.uploadTokenInfo.domain + result.key;
											this.imgListArrObj[index].succeed++;
										}
									})
								}
								// 对压缩的图片限制宽高
								if (ask && 'astrict' == ask) {
									this.imgListArrObj.forEach((item, index) => {
										if (imgName == item.name) {
											this.imgListArrObj[index].astrict = this
												.uploadTokenInfo.domain + result.key;
											this.imgListArrObj[index].succeed++;
										}
									})
								}
								// 同时上传普通图
								if (ask && 'thumbnail' == ask) {
									this.imgListArrObj.forEach((item, index) => {
										if (imgName == item.name) {
											this.imgListArrObj[index].thumbnail = this
												.uploadTokenInfo.domain + result.key;
											this.imgListArrObj[index].succeed++;
										}
									})
								}
							} else {
								this.maxLimitNum--;
								this.imgList.push(this.uploadTokenInfo.domain + result.key)
							}
							setTimeout(() => {
								this.$emit('success', this.imgList, this.imgListArrObj)
							}, 10)
						},
					})
				});
			},
			// 删除图片
			handleDel(item, index) {
				this.$alert('你确定要删除这张图片吗？', '提示', {
					confirmButtonText: '确定',
					callback: (action) => {
						if (action == 'confirm') {
							this.maxLimitNum++;
							this.needDelImg.push(item)
							this.imgList.splice(index, 1)
							this.imgListArrObj.splice(index, 1)
							this.$emit('success', this.imgList, this.imgListArrObj)
						}
					}
				});
			},
		}
	}
</script>

<style scoped>
	.cos-box {
		position: relative;
		display: flex;
		text-align: center;
		border: 1px solid #DCDFE6;
		padding: 15px 10px;
		border-radius: 8px;
		flex-wrap: wrap;
	}
	.title {
		position: absolute;
		padding: 5px 3px;
		background-color: #fff;
		left: 20px;
		top: -14px;
		line-height: 1.2;

	}
	.title.color-red {
		color: #f56c6c;
	}
	.img-box {
		position: relative;
		width: 150px;
		height: 150px;
		margin-right: 10px;
		margin-bottom: 10px;
		overflow: hidden;
		border-radius: 6px;
		border: 1px dashed #d9d9d9;
	}
	.img-box .img-box-thumbnail {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.img-box:hover .img-box-check {
		visibility: hidden;
	}
	.img-box-check {
		visibility: visible;
		position: absolute;
		right: -17px;
		top: -7px;
		width: 46px;
		height: 26px;
		background: #13ce66;
		text-align: center;
		transform: rotate(45deg);
		box-shadow: 0 1px 1px #ccc;
		color: #fff;
	}
	.el-icon-check {
		font-size: 12px;
		margin-top: 12px;
		transform: rotate(-45deg);
	}
	.img-box:hover .img-box-actions {
		visibility: visible;
	}
	.img-box-actions {
		visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(0, 0, 0, .3);
		font-size: 26px;
		color: #fff;
	}
	.img-box-icon {
		width: 50px;
	}
	.pointer {
		cursor: pointer;
	}
	.img-box .el-icon-close {
		position: absolute;
		right: 0;
		top: 0;
		font-size: 20px;
		color: #8c939d;
		background-color: rgba(0, 0, 0, .5);
		border-radius: 50%;
	}
	.add-box {
		background-color: #fbfdff;
		border: 1px dashed #c0ccda;
		border-radius: 6px;
		box-sizing: border-box;
		width: 148px;
		height: 148px;
		cursor: pointer;
		line-height: 146px;
		vertical-align: top;
	}
	.add-box .el-icon-plus {
		font-size: 28px;
		color: #8c939d;
	}
</style>
