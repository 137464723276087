<!--
 * @FilePath: \zhishe-pc\src\views\upload\componts\joinForm.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-12-20 19:49:36
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-21 09:37:13
-->
<template>
  <div class="formBox">
    <div class="joinBox">
      <img src="../../../../src/assets/image/upload/null.png" alt="" />
      <p>您暂未与知设网签约 无法上传课程</p>
      <router-link :to="{path:'/sign/index'}">
        <p>
          立即前往签约
          <span>&nbsp;>></span>
        </p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "JoinForm",
  data() {
    return {};
  },
};
</script>

<style scoped>
.formBox {
  width: 11rem;
  height: 4.88rem;
  background: #ffffff;
  opacity: 1;
  border-radius: 4px;
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.joinBox img {
  width: 2.8rem;
  height: 1.64rem;
}

.joinBox p:nth-child(2) {
  margin-top: 0.2rem;
  font-size: 0.12rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  opacity: 1;
  text-align: center;
}

.joinBox a p {
  margin-top: 0.1rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #040404;
  opacity: 1;
  text-align: center;
}

.joinBox span {
  color: #f46600;
}
</style>