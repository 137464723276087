<template>
  <div class="courseVideoList">
    <div class="courseItem">
			<div style="height: 0.24rem;">
				<div class="videoIndex c_p" v-if="chapterList.length > 1" @click="delChapterFun(id)">×</div>
			</div>
      <el-form
        :model="addVideoForm"
        :rules="rules"
        ref="addVideoForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="章节标题"
          style="margin-bottom: 0.3rem"
          prop="title"
        >
          <el-input
            class="w400"
            placeholder="请输入章节标题"
            v-model="addVideoForm.title"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传视频" required prop="files">
          <CourseMultUpload
            :id="id"
            :sections="chapterInfo.sections"
            @uploadFiles="uploadFiles"
          ></CourseMultUpload>
        </el-form-item>
      </el-form>
      <div class="videoIndex">{{ index }}</div>
    </div>
  </div>
</template>

<script>
import CourseMultUpload from "./CourseMultUpload";
import { editChapter } from "../../../Api";
import { getToken } from "../../../utils/auth";

export default {
  name: "CourseMultVideo",
  props: ["index", "id", "chapterInfo", "chapterList"],
  data() {
    return {
      addVideoForm: {
        title: "",
        files: null,
      },
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        files: [
          { required: true, message: "请上传章节视频", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    delChapterFun(id){
      this.$parent.delChapterFun(id);
    },
    //上传文件返回的数据
    uploadFiles(data) {
      this.addVideoForm.files = data;
    },
    //表单验证
    validForm() {
      return new Promise((resolve) => {
        this.$refs["addVideoForm"].validate((valid) => {
          if (valid) resolve();
        });
      });
    },
    //  修改章
    editChapterFun(data) {
      const params = {
        id: data.id,
        title: data.title,
        token: getToken(),
      };
      editChapter(params).then((res) => {
        if (res.data.message === "Success") console.log("修改章");
      });
    },
   
  },
  computed: {
    // 使用计算属性进行深拷贝
    newAddVideoForm() {
      return JSON.stringify(this.addVideoForm);
    },
  },
  watch: {
    newAddVideoForm: {
      handler(newVal, oldVal) {
        let newObj = JSON.parse(newVal);
        let oldObj = JSON.parse(oldVal);
        const data = {
          title: newObj.title,
          files: newObj.files,
          id: this.id,
        };
        this.$store.dispatch("upload/setCourseMultiVideo", data);
        //修改章标题时提交后修改到api
        if (newObj.title !== oldObj.title) this.editChapterFun(data);
      },
      deep: true,
    },
    chapterInfo: {
      // 深度监听
      handler(val) {
        this.addVideoForm.title = val.title;
        this.addVideoForm.files = val.sections;
        this.id = val.id;
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    CourseMultUpload,
  },
};
</script>

<style scoped>
.videoIndex {
  background: #333333;
  color: #ffffff;
  width: 0.24rem;
  font-size: 0.16rem;
  text-align: center;
  border-radius: 0.04rem;
  height: 0.24rem;
  line-height: 0.24rem;
  margin-left: auto;
}

.courseItem {
  margin-bottom: 00.1rem !important;
  background: #ffffff;
  /* padding: 0 0 0.4rem; */
  border-radius: 0.04rem;
}
</style>