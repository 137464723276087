<template>
  <div class="formList">
    <div v-for="(item,index) in list" :key="index">
      <div class="formItem" v-show="hiddenItem!==index">
        <div class="formImg">
          <img v-if="item.cover" :src="item.cover" alt="">
          <img v-else src="@/assets/image/bg/photo.jpg" alt="">
        </div>
        <div class="fromHead">
          <p v-if="item.materialForm.title">{{ item.materialForm.title }}</p>
          <p v-else>未填写标题</p>
          <div v-if="!item.isValid" class="formStatus">待完善</div>
          <div v-else class="formStatus-ok">已完善</div>
        </div>
        <div class="fromItemSetBox">
          <div class="fromItemSet">
            <p class="mr30 c_f46 f16 c_p" @click="anewEdit(item,index)">重新编辑</p>
            <p class="f16 c_bf c_p" @click="deleteItem(index)">删除</p>
          </div>
          <div v-if="!item.isValid" class="fromItemSetA">提交审核</div>
          <div v-else class="fromItemSetB" @click="submit(item)">提交审核</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditingUploadListSchemeText",
  data(){
    return {
      hiddenItem:-1
    }
  },
  props:['list'],
  methods:{
    deleteItem(index){
      this.$store.dispatch('upload/removeNewSchemeText',index)
    },
  //  从新编辑
    anewEdit(data,index){
      this.hiddenItem = index
      this.$emit('anewEditFun',data,index)
    },
  //  提交审核
    submit(data){
      this.$emit('submitAudit',data)
    }
  }
}
</script>

<style scoped>
.fromItemSetB {
  cursor: pointer;
  width: 1.25rem;
  margin: 0.18rem auto 0;
  text-align: center;
  height: 0.4rem;
  line-height: 0.4rem;
  background: #F46600;
  border-radius: 0.04rem;
  border: 0.01rem solid #F46600;
  font-size: 0.16rem;
  color: #FFFFFF;
  font-weight: bold;
}

.fromItemSetA {
  cursor: pointer;
  width: 1.25rem;
  margin: 0.18rem auto 0;
  text-align: center;
  height: 0.4rem;
  line-height: 0.4rem;
  background: #BFBFBF;
  border-radius: 0.04rem;
  border: 0.01rem solid #BFBFBF;
  font-size: 0.16rem;
  color: #FFFFFF;
  font-weight: bold;
}

.fromItemSet {
  display: flex;
  align-items: center;
}

.fromItemSetBox {
  margin-left: auto;
}

.formStatus {
  margin-top: 0.3rem;
  width: 0.7rem;
  height: 0.3rem;
  background: #FFDBDB;
  color: #E83266;
  text-align: center;
  font-size: 0.12rem;
  line-height: 0.3rem;
  border-radius: 0.04rem;
}

.formStatus-ok {
  margin-top: 0.3rem;
  width: 0.7rem;
  height: 0.3rem;
  background: #E5F8F5;
  color: #00BAA2;
  text-align: center;
  font-size: 0.12rem;
  line-height: 0.3rem;
  border-radius: 0.04rem;
}

.fromHead p {
  color: #040404;
  font-size: 0.14rem;
}

.formImg {
  width: 1.2rem;
  height: 0.9rem;
  overflow: hidden;
  border-radius: 0.04rem;
  margin-right: 0.2rem;
  /*background: coral;*/
}
.formImg img{
  width: 100%;
}

.formItem {
  padding: 0.2rem 0.3rem;
  background: #FFFFFF;
  border-radius: 0.04rem;
  overflow: hidden;
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
}
</style>