<template>
  <div>
    <div v-if="uploadTypeInfo.type == 'video'">
      <el-button
        style="width: 4rem"
        size="small"
        type="primary"
        :class="{ noActive: uploadFileObj.isFile }"
        @click="zh_uploadFile"
        >点击上传课程视频
      </el-button>
      <input
        accept=".mp4,.MP4"
        type="file"
        ref="evfile"
        @change="zh_uploadFile_change"
        style="display: none"
      />
    </div>
    <div v-else>
      <el-button
        style="width: 4rem"
        size="small"
        type="primary"
        :class="{ noActive: uploadFileObj.isFile }"
        @click="zh_uploadFile"
        >点击上传课程配套资源
      </el-button>
      <input
        accept=".zip,.rar,7z"
        type="file"
        ref="evfile"
        @change="zh_uploadFile_change"
        style="display: none"
      />
    </div>
    <div class="uploadVideoLis" v-if="uploadFileObj.isFile">
      <div class="uploadVideoItemBox">
        <div class="uploadVideoItem">
          <div class="uploadVideoItemName">
            <i class="iconfont" v-if="uploadTypeInfo.type === 'video'"
              >&#xef5e;</i
            >
            <i class="iconfont" style="color: #7a7dff" v-else>&#xef55;</i>
            <p class="txt-over">
              {{ uploadFileObj.fileName }}
            </p>
          </div>
          <div class="uploadSpeed mr40" v-if="!uploadFileObj.isSucceed">
            <p class="c_33 f14">上传中</p>
            <el-progress :percentage="uploadFileObj.fileUploadSchedule">
            </el-progress>
          </div>
          <div v-else class="uploadSpeed mr40">
            <p class="c_33 f14">上传成功</p>
          </div>
          <div class="uploadFileSet">
            <i
              class="iconfont c_p mr10"
              v-show="!uploadFileObj.isSucceed && uploadFileObj.isStop"
              @click="startUpload"
              >&#xe688;</i
            >
            <i
              class="iconfont c_p mr10"
              v-show="!uploadFileObj.isSucceed && !uploadFileObj.isStop"
              @click="stopUpload"
              >&#xe65b;</i
            >
            <i class="iconfont c_p" @click="cancelUpload">&#xe789;</i>
          </div>
        </div>
        <el-checkbox
          v-if="uploadTypeInfo.type === 'video' && uploadTypeInfo.isMulti"
          v-model="checked"
          >免费观看</el-checkbox
        >
      </div>
    </div>
  </div>
</template>
<script>
import { qiniuUpload } from "../../../utils/upload";
import { mapGetters } from "vuex";

export default {
  name: "uploadVideos",
  props: {
    uploadTypeInfo: {
      type: Object,
      default: () => ({
        type: "",
        isMulti: false,
      }),
    },
    courseSingleForm: {
      type: Object,
      default: () => ({}),
    },
    formType:{
      type:String
    }
  },
  data() {
    return {
      checked: false,
      observable: null,
      uploadObj: null,
      uploadFileObj: {
        isStop: false,
        isSucceed: false,
        isFile: false,
        fileName: "",
        fileUploadSchedule: 0,
      },
    };
  },
  methods: {
    //点击上传资源
    zh_uploadFile() {
      if (this.uploadFileObj.isFile) return;
      this.$refs.evfile.click();
    },
    zh_uploadFile_change(evfile) {
      const file = evfile.target.files[0];
      this.uploadFileObj.isFile = true;
      this.uploadFileObj.fileName = file.name;
      qiniuUpload(file, this.uploadTokenInfo.uptoken).then((res) => {
        this.observable = res;
        this.startUpload();
      });
    },
    // 开始上传
    startUpload() {
      this.uploadFileObj.isStop = false;
      this.uploadObj = this.observable.subscribe({
        next: (result) => {
          // 主要用来展示进度
          this.uploadFileObj.fileUploadSchedule = Math.floor(
            result.total.percent
          );
        },
        error: (errResult) => {
          // 失败报错信息
          this.$message.error("上传失败");
          console.log(errResult);
        },
        complete: (result) => {
          // 接收成功后返回的信息
          const index = result.key.lastIndexOf("."); //得到最后一个点的坐标
          const data = {
            filePath: this.uploadTokenInfo.domain + result.key,
            fileName: result.key.substring(0, index), //得到最后一个点之前的字符串
            fileOriginalName: this.uploadFileObj.fileName, // 文件原始名称
          };
          this.uploadFileObj.isSucceed = true;
          this.$emit("uploadRes", data);
        },
      });
    },
    //暂停上传
    stopUpload() {
      this.uploadFileObj.isStop = true;
      this.uploadObj.unsubscribe();
    },
    //删除、取消上传
    cancelUpload() {
      // this.uploadObj.unsubscribe();
      this.$data.uploadFileObj = this.$options.data().uploadFileObj;
      this.uploadObj = null;
      this.observable = null;
      this.$refs.evfile.value = null
      this.$emit("cancelRes");
    },
  },
  computed: {
    ...mapGetters(["uploadTokenInfo"]),
  },
  watch: {
    //编辑时 监听视频
    "courseSingleForm.videos": {
      handler(newValue) {
        if(newValue && this.formType == 'video')
        this.uploadFileObj = {
          isStop: false,
          isSucceed: true,
          isFile: true,
          fileName: newValue,
          fileUploadSchedule: 0,
        };
      }
    },
    //编辑时 监听文件
    "courseSingleForm.resources": {
      handler(newValue) {
        if(newValue && this.formType == 'resource')
        this.uploadFileObj = {
          isStop: false,
          isSucceed: true,
          isFile: true,
          fileName: newValue,
          fileUploadSchedule: 0,
        };
      },
    },
  },
};
</script>

<style scoped>
.noActive {
  background: #bfbfbf !important;
  border-color: #bfbfbf !important;
}

.uploadVideoItemBox >>> .el-checkbox__label {
  font-size: 00.14rem;
}

.uploadVideoItemBox >>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #00baa2;
}

.uploadVideoItemBox >>> .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #00baa2;
  border-color: #00baa2;
}

.uploadVideoItemBox >>> .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #dcdfe6;
}

.uploadVideoItemBox >>> .el-checkbox__inner:hover {
  border-color: #00baa2;
}

.uploadFileSet {
  font-size: 0.26rem;
}

.uploadSpeed >>> .el-progress {
  background: #ffffff;
  margin-left: 00.1rem;
  flex: 1;
}

.uploadSpeed >>> .el-progress__text {
  font-size: 00.14rem !important;
}

.uploadSpeed >>> .el-progress-bar__inner {
  background-color: #f46600;
}

.uploadSpeed {
  display: flex;
  align-items: center;
  width: 3rem;
}

.uploadVideoItemName i {
  color: #ff9068;
  font-size: 0.2rem;
  margin-right: 0.08rem;
}

.uploadVideoItemName p {
  color: #333333;
  font-size: 0.14rem;
}

.uploadVideoItemName {
  width: 2rem;
  margin-right: 00.2rem;
  display: flex;
  align-items: center;
}

.uploadVideoItem {
  height: 0.4rem;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 0.03rem rgb(102 102 102 / 20%);
  padding: 0 0.2rem;
  margin-right: 00.6rem;
}

.uploadVideoItemBox {
  display: flex;
  align-items: center;
  margin-top: 00.1rem;
}
</style>