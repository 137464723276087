<!--su上传表单组件-->
<template>
  <div>
    <div
      class="EditingUploadList"
      :class="{ isData: unSubmittedSu.length > 0 }"
    >
      <EditingUploadListSu
        ref="editList"
        @submitAudit="submitAudit"
        @anewEditFun="anewEditFun"
        :list="unSubmittedSu"
      ></EditingUploadListSu>
    </div>
    <!--草稿箱功能请参照素材上传，代码比较冗余自己理解。-->
    <div class="suForm">
      <el-form
        :model="suForm"
        :rules="rules"
        ref="suForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="上传图片" prop="images">
          <el-upload
            accept="image/png,image/jpg,image/jpeg"
            :class="{ imgHide: imgHideUpload }"
            drag
            class="avatar-uploader"
            :action="$ROOT_DIR + '/comms/Release/uploadPics?token=' + token"
            list-type="picture-card"
            :multiple="true"
            :limit="4"
            :on-exceed="handleExceed"
            :on-preview="handlePictureCardPreview"
            :on-remove="removeImgs"
            :file-list="loadImg"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <i class="el-icon-plus"></i>
            <div class="el-upload__text uploadTx">
              <p>请上传对应的图片</p>
              <p>支持多选上传图片</p>
            </div>
            <div
              class="el-upload__tip f12 c_bf"
              slot="tip"
              style="line-height: 0.25rem"
            >
              提示：请上传尺寸大于1000像素*750像素且高宽比为4:3的JPG/JPEG/PNG格式图片，大小小于2M，默认上传第一张图为封面。
            </div>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
        <el-form-item label="上传模型" required>
          <div class="df_Yc w400">
            <el-form-item prop="su_version" class="mr20">
              <el-select v-model="suForm.su_version" placeholder="请选择版本">
                <el-option
                  v-for="(item, index) in suAttrObj.version"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="uploadType">
              <el-select
                v-model="suForm.uploadType"
                placeholder="请选择上传方式"
                @change="changeUpType"
              >
                <el-option label="本地文件" value="0"></el-option>
                <el-option label="网盘链接" value="1"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div
            class="mt10"
            v-if="suForm.uploadType == '0'"
            style="margin-top: 0.2rem"
          >
            <div style="display: flex">
              <div>
                <el-form-item class="uploadFile" prop="suFile">
                  <div>
                    <el-button
                      size="small"
                      type="primary"
                      class="w400"
                      :class="{ noActive: suFileObj.isFile }"
                      @click="zh_uploadFile"
                    >
                      点击上传
                    </el-button>
                    <input
                      accept=".zip,.rar,.7z"
                      type="file"
                      ref="evfile"
                      @change="zh_uploadFile_change"
                      style="display: none"
                    />
                  </div>
                  <div class="el-upload__tip">
                    提示：附件以rar、zip、7z压缩包格式上传,文件大小需小于500M
                  </div>
                  <div class="uploadFileList" v-show="suFileObj.isFile">
                    <div class="uploadFileItem mt30">
                      <div class="fileName">
                        <i class="iconfont">&#xef55;</i>
                        <p class="f14 c_33 txt-over">{{ suFileObj.suFile }}</p>
                        <!-- <el-input ref="suFileNameInput" v-model="suFileObj.suFile" @blur="updateSuFileNameStatus = false" v-else></el-input> -->
                      </div>
                      <div class="uploadStatus" v-show="!suFileObj.isSucceed">
                        <p class="mr10">上传中</p>
                        <el-progress
                          class="statusBar"
                          :percentage="suFileObj.suFileUploadSchedule"
                          color="#F46600"
                        ></el-progress>
                      </div>
                      <div class="uploadStatus" v-show="suFileObj.isSucceed">
                        <p class="mr10">上传完成</p>
                      </div>
                      <div class="uploadSet">
                        <i
                          class="iconfont"
                          v-show="!suFileObj.isSucceed && suFileObj.isStop"
                          @click="startUpload"
                          >&#xe688;</i
                        >
                        <i
                          class="iconfont"
                          v-show="!suFileObj.isSucceed && !suFileObj.isStop"
                          @click="stopUpload"
                          >&#xe65b;</i
                        >
                        <i class="iconfont" @click="cancelUpload">&#xe789;</i>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </div>
              <div class="tipsBox mt10" v-if="!suFileObj.isFile">
                <img class="tipImg" src="@/assets/image/icon/help.png" alt="" />
                <div class="tipsCont">
                  <p>友情提示：</p>
                  <p>1.附件以rar、zip、7z压缩包格式上传,文件大小需小于500M；</p>
                  <p>2.超过500M以上的模型，建议用网盘链接；</p>
                  <p>
                    3.建议上传07、2011、2012、2014、2016、2018、2019、2020主流版本；
                  </p>
                   <p>注：500M以内的模型，用网盘链接不会被审核通过。</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="df_Yc w400 mt40">
            <el-form-item class="mr10" prop="suFile" style="flex: 1">
              <el-input
                v-model="suForm.suFile"
                placeholder="请输入网盘链接"
              ></el-input>
            </el-form-item>
            <el-form-item prop="linkPass" style="width: 1.5rem">
              <el-input
                v-model="suForm.linkPass"
                placeholder="请输入提取密码"
              ></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input
            class="w400"
            placeholder="请输入模型的标题"
            v-model="suForm.title"
          ></el-input>
        </el-form-item>
        <el-form-item label="设计风格" prop="style">
          <el-select v-model="suForm.style" placeholder="设计风格" class="w400">
            <el-option
              v-for="(item, index) in suAttrObj.style"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类" required>
          <el-col :span="7" style="width: 1.9rem" v-if="menuList.length > 0">
            <el-form-item prop="classify1">
              <el-select
                v-model.number="suForm.classify1"
                placeholder="请选择分类"
                @change="selectClass_1"
              >
                <el-option
                  v-for="(item, index) in menuList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="1" class="segmentation" v-if="menuChildList.length > 0"
            >-</el-col
          >
          <el-col
            :span="7"
            style="width: 1.9rem"
            v-if="menuChildList.length > 0"
          >
            <el-form-item prop="classify2">
              <el-select
                v-model.number="suForm.classify2"
                placeholder="请选择分类"
                @change="selectClass_2"
              >
                <el-option
                  v-for="(item, index) in menuChildList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :span="1"
            class="segmentation"
            v-if="menuChildList2.length > 0"
            >-</el-col
          >
          <el-col
            :span="7"
            style="width: 1.9rem"
            v-if="menuChildList2.length > 0"
          >
            <el-form-item prop="classify3">
              <el-select
                v-model.number="suForm.classify3"
                placeholder="请选择分类"
              >
                <el-option
                  v-for="(item, index) in menuChildList2"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="模型推荐" prop="recommendType">
          <el-radio-group v-model="suForm.recommendType">
            <el-radio
              v-for="(item, index) in attrList"
              :key="index"
              :label="item.id"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="渲染器" prop="renderer">
          <el-radio-group v-model="suForm.renderer">
            <el-radio
              v-for="(item, index) in suAttrObj.Renderer"
              :key="index"
              :label="item"
              >{{ item }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="材质贴图" prop="chartlet">
          <el-radio-group v-model="suForm.sticker">
            <el-radio label="1">有</el-radio>
            <el-radio label="0">无</el-radio>
            <el-radio label="">未知</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="priceTips">
          <el-form-item label="价格" prop="price">
            <el-select
              v-model="suForm.price"
              placeholder="请选择模型价格"
              class="w400"
            >
              <el-option
                v-for="(item, index) in suAttrObj.price"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="tipsBox">
            <img class="tipImg" src="@/assets/image/icon/help.png" alt="" />
            <div class="tipsCont">
              <p>友情提示：</p>
              <p>1.根据模型质量，设置合适的价格；</p>
              <p>2.模型价格越低，被下载的几率越大，收益更多；</p>
            </div>
          </div>
        </div>
        <el-form-item prop="redAgreement" class="agreement">
          <el-checkbox v-model="suForm.redAgreement">
            <div class="c_66 f12">
              我已阅读并同意
              <router-link
                :to="{ path: '/other/index', query: { type: 2 } }"
                target="_blank"
                ><span class="c_f46">《知设网协议》</span></router-link
              >
            </div>
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('suForm')"
            >提交审核</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="fromBottom">
      <div>
        <div class="fromBottomTitle">
          <img src="@/assets/image/icon/zsw-icon.png" alt="" />
          <p>作品上传说明</p>
        </div>
        <div class="uploadSpecification">
          <p>
            1.
            请精准填写模型标题，版本，准确选择分类，可提高模型被检索到的概率；
          </p>
          <p>
            2.
            上传图片建议上传3张以上，第一张为素材封面，一定要漂亮哦；像素≥1000*750；
          </p>
          <p>
            3. 图片过小/带水印图片和模型不符者，含有广告性质模型审核不予通过；
          </p>
          <p>
            4. 图片格式支持jpg、 png等，模型文件为rar、
            zip、7z等格式的压缩包，大小不超过500M；
          </p>
          <p>
            5. SU模型文件建议上传主流低版本(Sketchup
            2018及以下)，可大大提高模型被下的几率；
          </p>
          <p>
            6.
            知设网鼓励上传“原创模型”，若上传模型与本网站他人作品雷同，将作出下架处理；
          </p>
          <p>
            7.
            模型的意向价格为(0-9)知币；根据模型质量，知设网有权修改价格到合适区间；
          </p>
          <p>8. 作品审核时间为48小时，节假日顺延；</p>
        </div>
      </div>
      <div>
        <div class="fromBottomTitle">
          <img src="@/assets/image/icon/zsw-icon.png" alt="" />
          <p>作品收益说明</p>
        </div>
        <div class="uploadSpecification">
          <p>
            1.
            “原创资料”的单独赞赏收益，其中50%归上传者所有，收益达到100元即可提现。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditingUploadListSu from "@/views/upload/componts/EditingUploadListSu";
import {
  addNewSu,
  getAttr,
  getAttributes,
  uploadMaterialImg,
  suEdit,
  editSu,
  getProNavList,
} from "../../../Api";
import { getToken } from "@/utils/auth";
import { qiniuUpload } from "../../../utils/upload";
import { mapGetters } from "vuex";
import { Loading } from "element-ui";

export default {
  name: "SuForm",
  components: {
    EditingUploadListSu,
  },
  data() {
    let validateAgreement = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选阅读并同意《知设网协议》"));
      } else {
        callback();
      }
    };
    let validateUrl = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入文件链接或上传文件"));
      } else {
        const reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\\/?%&=]*)?/;
        let objExp = new RegExp(reg);
        let objExp1 = new RegExp("[\\u4E00-\\u9FFF]+","g");
        if (objExp.test(value) == true && objExp1.test(value) == false) {
          callback();
        } else {
          return callback(new Error("请输入正确文件链接格式"));
        }
      }
    };
    return {
      isValid: false,
      draftIndex: 0,
      updateSuFileNameStatus: false,
      token: getToken(),
      imgHideUpload: false, // 控制上传图片显示
      loadImg: [], /// 上传图片的原件
      cover: "", // 封面
      imageFile: [],
      suAttrObj: {
        price: [], // 价格
        style: [], //风格
        version: [], // 版本
        Renderer: [], //渲染器
      },
      attrList: [], // 推荐属性
      menuList: [], // 菜单分类
      menuChildList: [], // 二级菜单分类
      menuChildList2: [], // 三级菜单分类
      suForm: {
        linkPass: "", // 网盘链接密码
        suFile: "", // 配套资源
        suFileName: "", // 配套资源文件名
        images: [],
        uploadType: "0", // 默认上传本地文件
        su_version: "",
        title: "",
        style: "",
        classify1: "",
        classify2: "",
        classify3: "",
        recommendType: "",
        renderer: "",
        sticker: "",
        price: "",
        redAgreement: false,
        editId: "",
      },
      eidtForm: {},
      rules: {
        linkPass: [
          { required: true, message: "请输入提取密码", trigger: "blur" },
        ],
        images: [{ required: true, message: "请上传图片", trigger: "change" }],
        suFile: [{ validator: validateUrl, trigger: "change" }],
        su_version: [
          { required: true, message: "请选择版本", trigger: "change" },
        ],
        uploadType: [
          { required: true, message: "请选择上传方式", trigger: "change" },
        ],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        style: [
          { required: true, message: "请选择设计风格", trigger: "change" },
        ],
        classify1: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
        classify2: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
        classify3: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
        recommendType: [
          { required: true, message: "请选择模型推荐", trigger: "change" },
        ],
        rendererType: [
          { required: true, message: "请选择渲染器", trigger: "change" },
        ],
        renderer: [
          { required: true, message: "请选择渲染器", trigger: "change" },
        ],
        sticker: [
          { required: true, message: "请选择材质贴图", trigger: "change" },
        ],
        price: [
          { required: true, message: "请选择模型价格", trigger: "change" },
        ],
        redAgreement: [{ validator: validateAgreement, trigger: "change" }],
      },
      dialogImageUrl: "",
      dialogVisible: false,
      suFileObj: {
        isStop: false,
        isSucceed: false,
        isFile: false,
        suFile: "",
        suFileUploadSchedule: 0,
      },
      loadingInstance: null,
      uploadObj: null,
    };
  },
  created() {
    this.initData();
    Object.assign(this.$data, this.$options.data());
    //判断是否是编辑
    if (this.$route.query.id) {
      this.suForm.editId = this.$route.query.id;
      suEdit({ id: this.$route.query.id, token: getToken() }).then((res) => {
        this.eidtForm = res.data;
        //处理图片
        this.suForm.images = this.eidtForm.picsStr;
        this.eidtForm.picsStr.forEach((item) => {
          this.loadImg.push({ name: item, url: item });
        });
        //处理版本
        this.suForm.su_version = this.eidtForm.su_version;
        this.suForm.uploadType = this.eidtForm.upWay;
        //标题
        this.suForm.title = this.eidtForm.title;
        //风格
        this.suForm.style = this.eidtForm.style;
        //分类
        this.suForm.classify1 = parseInt(this.eidtForm.fidPath.split(",")[3]);
        //获取二级分类
        this.suForm.classify2 = parseInt(this.eidtForm.fidPath.split(",")[4]);
        this.suForm.classify3 = parseInt(this.eidtForm.fidPath.split(",")[5]);
        //获取选中的三级分类菜单
        this.getThreeMenuList(this.suForm.classify1, this.suForm.classify2);
        //渲染器
        this.suForm.renderer = this.eidtForm.renderer;
        //模型推荐
        this.suForm.recommendType = this.eidtForm.yc;
        //贴图
        this.suForm.sticker = this.eidtForm.sticker.toString();
        //价格
        this.suForm.price = this.eidtForm.zsStr;
        //判断文件是网盘还是上传
        if (this.suForm.uploadType == 0) {
          //文件
          this.suForm.suFile = this.eidtForm.filePath;
          this.suFileObj = {
            isStop: false,
            isSucceed: true,
            isFile: true,
            suFile: this.eidtForm.filePath,
            suFileUploadSchedule: 0,
          };
        } else {
          //网盘密码
          this.suForm.linkPass = this.eidtForm.baiduCode;
          this.suForm.suFile = this.eidtForm.filePath;
        }
      });
    }
  },
  methods: {
    //上传图片前的图片验证回调
    beforeAvatarUpload(file) {
      const _this = this;
      const isSize = new Promise(function (resolve, reject) {
        const img = new Image();
        const _URL = window.URL || window.webkitURl;
        img.onload = function () {
          file.width = img.width; //图片宽度
          file.height = img.height; //图片高度
          const valid = img.width >= 1000 && img.height >= 750; //上传图片尺寸判定
          const valid1 = (img.height / img.width).toFixed(2) == 0.75
          const valid2 = file.size / 1024 < 2048;
          valid && valid1 && valid2 ? resolve() : reject(new Error("error"));
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          _this.$message.error('图片 "比例或大小" 不对，请重新上传');
          return Promise.reject(new Error("error"));
        }
      );
      return isSize;
    },
    focusInput() {
      this.updateSuFileNameStatus = true;
      this.$nextTick(() => this.$refs.suFileNameInput.focus());
    },
    //上传成功
    handleAvatarSuccess(res) {
      if (res.data.message == "uploadSuccess") {
        this.suForm.images.push(res.data.filePath);
      }
    },
    initData() {
      const resList = [this.getSuAttr(), this.getMenuList(), this.getAttr()];
      Promise.all(resList).then(() => {});
    },
    //获取SU属性
    getSuAttr() {
      return new Promise((resolve) => {
        getAttributes().then((res) => {
          this.suAttrObj = res.data;
          resolve();
        });
      });
    },
    //获取推荐属性
    getAttr() {
      return new Promise((resolve) => {
        getAttr(getToken()).then((res) => {
          this.attrList = res.data;
          resolve();
        });
      });
    },
    //切换上传类型
    changeUpType() {
      // this.$data.suFileObj = this.$options.data().suFileObj;
      this.suForm.suFile = "";
      this.suForm.suFileName = "";
      this.suForm.linkPass = "";
    },
    //点击上传资源
    zh_uploadFile() {
      if (this.suFileObj.isFile) return;
      this.$refs.evfile.click();
    },
    //获取七牛云上传对象
    zh_uploadFile_change(evfile) {
      const file = evfile.target.files[0];
      this.suFileObj.isFile = true;
      this.suFileObj.suFile = file.name;
      qiniuUpload(file, this.uploadTokenInfo.uptoken).then((res) => {
        this.observable = res;
        this.startUpload();
      });
    },
    //开始上传
    startUpload() {
      this.suFileObj.isStop = false;
      this.uploadObj = this.observable.subscribe({
        next: (result) => {
          this.suFileObj.suFileUploadSchedule = Math.floor(
            result.total.percent
          );
        },
        error: (errResult) => {
          this.$message.error("上传失败");
          console.log(errResult);
        },
        complete: (result) => {
          this.suForm.suFile = this.uploadTokenInfo.domain + result.key;
          const index = result.key.lastIndexOf("."); //得到最后一个点的坐标
          this.suForm.suFileName = result.key.substring(0, index);
          this.suFileObj.isSucceed = true;
        },
      });
    },
    //暂停上传
    stopUpload() {
      this.suFileObj.isStop = true;
      this.uploadObj.unsubscribe();
    },
    //删除删除、取消上传
    cancelUpload() {
      if (this.uploadObj) this.uploadObj.unsubscribe();
      this.suFileObj = {
        isStop: false,
        isSucceed: false,
        isFile: false,
        suFile: "",
        suFileUploadSchedule: 0,
      };
      // this.$data.suFileObj = this.$options.data().suFileObj;
      this.suForm.suFile = "";
      this.uploadObj = null;
      this.observable = null;
    },
    //选择一级菜单
    selectClass_1(val) {
      this.getMenuList(val, 2);
    },
    //选择二级菜单
    selectClass_2(val) {
      this.getMenuList(val, 3);
    },
    //获取su菜单分类
    getMenuList(id = 561, rank = 1) {
      return new Promise((resolve) => {
        getProNavList({ id: id, token: getToken() }).then((res) => {
          if (rank === 1) {
            //一级菜单
            this.menuList = res.data;
            this.menuChildList = [];
            this.menuChildList2 = [];
          } else if (rank === 2) {
            this.menuChildList = res.data;
            this.menuChildList2 = [];
            this.suForm.classify2 = "";
            this.suForm.classify3 = "";
          } else {
            this.menuChildList2 = res.data;
            this.suForm.classify3 = "";
          }
          resolve();
        });
      });
    },
    //编辑时获取三级分类
    getThreeMenuList(classify1, classify2) {
      return new Promise((resolve) => {
        getProNavList({ id: classify1, token: getToken() }).then((res) => {
          this.menuChildList = res.data;
        });
        getProNavList({ id: classify2, token: getToken() }).then((res) => {
          this.menuChildList2 = res.data;
        });
        resolve();
      });
      
    },
    //上传数量验证
    handleExceed() {
      this.$message.warning(`最多只能上传4张图片`);
    },
    //放大显示图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadImg(param) {
      console.log(param);
    },
    //删除图片
    removeImgs(file, fileList) {
      this.imgHideUpload = fileList.length >= 4;
      //从表单对象中删除
      this.suForm.images = this.suForm.images.filter(function (item) {
        if (file.response) {
          return item != file.response.data.filePath;
        }
        if (file.url) {
          return item != file.url;
        }
      });
    },
    //上传图片列表
    uploadFiles(data) {
      return new Promise((resolve, reject) => {
        const len = data.suForm.images.length;
        let uploadCot = 0;
        data.suForm.images.forEach((item) => {
          let formData = new FormData();
          formData.append("file", item.raw);
          uploadMaterialImg(getToken(), formData)
            .then((res) => {
              if (res.data.message === "uploadSuccess") {
                data.imageFile.push(res.data.filePath);
                uploadCot += 1;
                if (uploadCot === len) {
                  resolve();
                }
              } else {
                console.log("上传失败");
                const message = "上传图片失败，请刷新页面重试";
                reject(message);
              }
            })
            .catch((error) => {
              console.log("错误", error);
              const message = "上传失败";
              reject(message);
            });
        });
      });
    },
    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loadingInstance = Loading.service({ fullscreen: true });
          this.saveData(this.$data);
        } else {
          return false;
        }
      });
    },
    saveData(data) {
      let fidPath = "";
      if (data.suForm.classify3) {
        fidPath = [
          data.suForm.classify1,
          data.suForm.classify2,
          data.suForm.classify3,
        ].join(",");
      } else {
        fidPath = [data.suForm.classify1, data.suForm.classify2].join(",");
      }

      let params = {
        cover: data.suForm.images[0],
        fid: data.suForm.classify3 || data.suForm.classify2,
        fidPath: fidPath,
        fileName: data.suForm.suFileName,
        filePath: data.suForm.suFile,
        free: "1",
        introduce: data.suForm.title,
        picsStr: data.suForm.images.join(","),
        title: data.suForm.title,
        token: getToken(),
        type: 561,
        upWay: data.suForm.uploadType,
        yc: data.suForm.recommendType,
        zsStr: data.suForm.price,
        style: data.suForm.style,
        renderer: data.suForm.renderer,
        su_version: data.suForm.su_version,
        sticker: data.suForm.sticker,
      };
      if (this.suForm.uploadType == 1) {
        params["baiduCode"] = data.suForm.linkPass;
        delete params.fileName;
      }
      //判断是编辑还是新增
      if (this.suForm.editId) {
        params.id = this.suForm.editId;
        editSu(params).then((res) => {
          if (res.data.error === 0 && res.data.message === "editSuccess") {
            this.$message.success("编辑成功！等待平台审核");
            this.$router.push({
              path: "/upload/myUpload",
              query: { activeName: "su", uploadType: 2 },
            });
          } else {
            this.$message.error("编辑失败！请刷新页面重新填写");
          }
        });
      } else {
        addNewSu(params).then((res) => {
          if (res.data.error === 0 && res.data.message === "addSuccess") {
            this.$message.success("发布成功！等待平台审核");
            this.$router.push({
              path: "/upload/myUpload",
              query: { activeName: "su", uploadType: 2 },
            });
          } else {
            this.$message.error("发布失败！请刷新页面重新填写");
          }
        });
      }
      this.loadingInstance.close();
    },
    // 父组件点击添加新作品
    addNewWork() {
      return new Promise((resolve) => {
        this.$refs["suForm"].validate((valid) => {
          if (valid) {
            this.isValid = true;
          }
        });
        let index = -1;
        if (this.$refs["editList"].hiddenItem !== -1) {
          index = this.$refs["editList"].hiddenItem;
          this.$refs["editList"].hiddenItem = -1;
        }
        const data = {
          data: this.$data,
          index: index,
        };
        this.$store.dispatch("upload/addNewSu", data).then(() => {
          resolve();
        });
      });
    },
    // 提交审核（子组件触发的哟）
    submitAudit(data) {
      this.loadingInstance = Loading.service({ fullscreen: true });
      //  上传图片
      this.uploadFiles(data)
        .then(() => {
          //保存数据
          this.saveData(data);
        })
        .catch((error) => {
          this.loadingInstance.close();
          this.$message.error(error);
        });
    },
    //  从新编辑
    anewEditFun(data, index) {
      this.draftIndex = index;
      data.loadImg = [];
      // data.SuForm.images.forEach((item) => {
      //   data.loadImg.push({ url: URL.createObjectURL(item.raw) });
      // });
      Object.assign(this.$data, data);
    },
  },
  mounted() {
    // this.initData();
  },
  computed: {
    ...mapGetters(["uploadTokenInfo", "unSubmittedSu"]),
  },
};
</script>

<style scoped>
.noActive {
  background: #bfbfbf !important;
  border-color: #bfbfbf !important;
}
.uploadSet {
  flex: 1;
  font-size: 0.25rem;
  text-align: right;
  color: #666666;
}
.avatar-uploader-icon {
  font-size: 0.28rem;
  color: #8c939d;
  text-align: center;
}
.uploadSet i {
  margin: 0 0.05rem;
  cursor: pointer;
}
.statusBar {
  flex: 1;
}
.uploadStatus {
  width: 3rem;
  display: flex;
  align-items: center;
}
.uploadFileItem {
  width: 6.56rem;
  height: 0.4rem;
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  color: #333333;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 0px 0.06rem rgba(102, 102, 102, 0.2);
  border-radius: 0.04rem;
  padding: 0 0.2rem;
}
.fileName {
  display: flex;
  align-items: center;
  /*line-height: 0.2rem;*/
  width: 2rem;
  margin-right: 0.5rem;
}

.fileName i {
  color: #7a7dff;
  font-size: 0.18rem;
  margin-right: 0.08rem;
}
.uploadFile >>> .el-upload__tip {
  line-height: 0.18rem;
}
.imgHide >>> .el-upload--picture-card {
  display: none !important;
}

.cover {
  line-height: 0;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 2rem;
  height: 1.5rem;
  margin-right: 0.2rem;
  margin-top: 0;
}

::v-deep .el-upload--picture-card {
  width: 2rem;
  height: 1.5rem;
  border: none !important;
}

.tipImg:hover + .tipsCont {
  visibility: visible;
  opacity: 1;
}

.tipsCont {
  position: absolute;
  background: #666666;
  border-radius: 0.04rem;
  padding: 0.15rem;
  width: 3.16rem;
  color: #ffffff;
  font-size: 0.12rem;
  line-height: 0.2rem;
  top: 0.4rem;
  left: 0.3rem;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}

.tipsBox {
  position: relative;
}

.tipsBox img {
  width: 0.24rem;
  height: 0.24rem;
  margin-bottom: 0.4rem;
  margin-left: 0.3rem;
  cursor: pointer;
}

.priceTips {
  display: flex;
  align-items: center;
}

.uploadSpecification {
  margin-top: 0.2rem;
  margin-left: 0.25rem;
}

.uploadSpecification p {
  color: #666666;
  font-size: 0.14rem;
  margin-bottom: 0.25rem;
}

.fromBottomTitle {
  display: flex;
  align-items: center;
}

.fromBottomTitle img {
  width: 0.16rem;
  height: 0.19rem;
  margin-right: 0.1rem;
}

.fromBottomTitle p {
  font-size: 0.16rem;
  color: #040404;
  font-weight: bold;
}

.fromBottom {
  width: 100%;
  margin-top: 0.1rem;
  margin-bottom: 0.3rem;
  padding: 0.2rem 0.3rem 0.4rem;
  background: #ffffff;
  border-radius: 0.04rem;
}

.suForm {
  width: 100%;
  margin-top: 0.1rem;
  padding: 0.2rem 0 0.4rem;
  background: #ffffff;
  border-radius: 0.06rem;
}

.segmentation {
  width: 0.2rem;
  text-align: center;
}

.suForm >>> .uploadTx {
  font-size: 0.12rem;
  color: #bfbfbf;
  line-height: 0.3rem;
  margin: 0.1rem auto 0;
}

.suForm >>> .el-radio__label {
  font-size: 00.14rem;
}

.suForm >>> .el-upload-dragger {
  width: 2rem;
  height: 1.5rem;
  display: flex;
  flex-direction: column;
  padding-top: 0.3rem;
  background: #f7f6fb;
  border: none !important;
}

.suForm >>> .el-input {
  font-size: 0.14rem;
}

.suForm >>> .el-form-item {
  margin-bottom: 0.4rem;
}

.suForm >>> .el-form-item .el-form-item {
  margin-bottom: 0;
}

.suForm >>> .el-radio__input.is-checked + .el-radio__label {
  color: #f46600;
}

.suForm >>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #f46600;
  background: #f46600;
}

.suForm >>> .el-input {
  font-size: 0.14rem;
}

.suForm >>> .el-input__inner {
  height: 0.4rem;
  line-height: 0.4rem;
}

.suForm >>> .el-button + .el-button {
  font-size: 0.14rem;
}

.suForm >>> .el-button--primary {
  width: 1.55rem;
  background-color: #f46600;
  border-color: #f46600;
  font-size: 00.14rem;
}
.uploadFile >>> .el-button--primary {
  width: 4rem;
  background-color: #f46600;
  border-color: #f46600;
  font-size: 00.14rem;
  height: 0.44rem;
  line-height: 0.44rem;
  padding: 0;
}
</style>