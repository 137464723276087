import * as qiniu from "qiniu-js";
const utf8_encode = function(argString){
    if (argString === null || typeof argString === 'undefined') {
        return '';
    }

    var string = (argString + ''); // .replace(/\r\n/g, '\n').replace(/\r/g, '\n');
    var utftext = '',
        start, end, stringl = 0;

    start = end = 0;
    stringl = string.length;
    for (var n = 0; n < stringl; n++) {
        var c1 = string.charCodeAt(n);
        var enc = null;

        if (c1 < 128) {
            end++;
        } else if (c1 > 127 && c1 < 2048) {
            enc = String.fromCharCode(
                (c1 >> 6) | 192, (c1 & 63) | 128
            );
        } else if (c1 & 0xF800 ^ 0xD800 > 0) {
            enc = String.fromCharCode(
                (c1 >> 12) | 224, ((c1 >> 6) & 63) | 128, (c1 & 63) | 128
            );
        } else { // surrogate pairs
            if (c1 & 0xFC00 ^ 0xD800 > 0) {
                throw new RangeError('Unmatched trail surrogate at ' + n);
            }
            var c2 = string.charCodeAt(++n);
            if (c2 & 0xFC00 ^ 0xDC00 > 0) {
                throw new RangeError('Unmatched lead surrogate at ' + (n - 1));
            }
            c1 = ((c1 & 0x3FF) << 10) + (c2 & 0x3FF) + 0x10000;
            enc = String.fromCharCode(
                (c1 >> 18) | 240, ((c1 >> 12) & 63) | 128, ((c1 >> 6) & 63) | 128, (c1 & 63) | 128
            );
        }
        if (enc !== null) {
            if (end > start) {
                utftext += string.slice(start, end);
            }
            utftext += enc;
            start = end = n + 1;
        }
    }

    if (end > start) {
        utftext += string.slice(start, stringl);
    }

    return utftext;
}
const base64_encode = function (data){
    var b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    var o1, o2, o3, h1, h2, h3, h4, bits, i = 0,
        ac = 0,
        enc = '',
        tmp_arr = [];

    if (!data) {
        return data;
    }

    data = utf8_encode(data + '');

    do { // pack three octets into four hexets
        o1 = data.charCodeAt(i++);
        o2 = data.charCodeAt(i++);
        o3 = data.charCodeAt(i++);

        bits = o1 << 16 | o2 << 8 | o3;

        h1 = bits >> 18 & 0x3f;
        h2 = bits >> 12 & 0x3f;
        h3 = bits >> 6 & 0x3f;
        h4 = bits & 0x3f;

        // use hexets to index into b64, and append result to encoded string
        tmp_arr[ac++] = b64.charAt(h1) + b64.charAt(h2) + b64.charAt(h3) + b64.charAt(h4);
    } while (i < data.length);

    enc = tmp_arr.join('');

    switch (data.length % 3) {
        case 1:
            enc = enc.slice(0, -2) + '==';
            break;
        case 2:
            enc = enc.slice(0, -1) + '=';
            break;
    }

    return enc;
}
const URLSafeBase64Encode = function(v){
    v = base64_encode(v);
    return v.replace(/\//g, '_').replace(/\+/g, '-');
}
export function qiniuUpload(file,token,mimeType=null){
    return new Promise(resolve=>{
        const config = {
            useCdnDomain: true,  //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
            disableStatisticsReport: true, //是否禁用日志报告，为布尔值，默认为false。
            retryCount: 3,  //上传自动重试次数（整体重试次数，而不是某个分片的重试次数）；默认 3 次（即上传失败后最多重试两次）；目前仅在上传过程中产生 599 内部错误时生效，但是未来很可能会扩展为支持更多的情况。
            region: qiniu.region.z2, //选择上传域名区域；当为 null 或 undefined 时，自动分析上传域名区域
            checkByMD5: true  // 是否开启 MD5 校验，为布尔值；在断点续传时，开启 MD5 校验会将已上传的分片与当前分片进行 MD5 值比对，若不一致，则重传该分片，避免使用错误的分片。读取分片内容并计算 MD5 需要花费一定的时间，因此会稍微增加断点续传时的耗时，默认为 false，不开启。
        };
        let putExtra = {
            fname: "",  //文件原文件名
            params: {}, //用来放置自定义变量
            mimeType: mimeType  //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
        };
        var extarr = file['name'].split('.');
        let prename = '',ext=''
        if (extarr.length === 1) {
            var arr = file['type'].split('/');
             prename = extarr[0];
             ext = (arr[arr.length - 1] == 'undefined') ? '' : arr[arr.length - 1];
        } else {
             ext = '.' + extarr[extarr.length - 1]; //得到后缀
             const index = file['name'].lastIndexOf('.');//得到最后一个点的坐标
             prename = file['name'].substring(0, index);//得到最后一个点之前的字符串
        }
        var timer = Date.parse(new Date());
        const key = timer + "*" + URLSafeBase64Encode(prename) + ext;
				console.log(key);
        putExtra.params["x:name"] = file.name.split(".")[0];
        const uploadObj = qiniu.upload(file, null, token, putExtra, config);
        resolve(uploadObj)
    })
}