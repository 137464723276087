<template>
  <div>
    <div>
      <el-button
        style="width: 4rem"
        size="small"
        type="primary"
        @click="addFileDom('videoFile')"
        >点击上传章节视频</el-button
      >
      <input
        accept=".mp4,.MP4"
        type="file"
        ref="videoFile"
        @change="addVideoFile($event)"
        style="display: none"
      />
    </div>
    <div>
      <div class="uploadVideoLis">
        <div v-for="(item, index) in videoInfoList" :key="index">
          <div class="uploadVideoItemBox" v-if="item.video.isFile">
            <div class="uploadVideoItem">
              <div class="df_Yc videoBox">
                <div class="uploadVideoItemName" v-if="!item.video.isSucceed">
                  <i class="iconfont">&#xef5e;</i>
                  <p class="txt-over">{{ item.video.fileOriginal }}</p>
                </div>
                <div class="titleBox" v-else>
                  <p>本节标题</p>
                  <el-input
                    v-model="item.video.title"
                    placeholder="请输入本节标题"
                    @blur="editSectionFun(index)"
                  ></el-input>
                </div>
                <div class="uploadSpeed mr40">
                  <p class="c_33 f14">
                    {{ item.video.isSucceed ? "视频上传成功" : "上传中" }}
                  </p>
                  <el-progress
                    v-show="!item.video.isSucceed"
                    :percentage="item.video.fileUploadSchedule"
                  >
                  </el-progress>
                </div>
                <div class="uploadFileSet">
                  <i
                    class="iconfont c_p mr10"
                    v-show="!item.video.isSucceed && item.video.isStop"
                    @click="startUpload(item.video, 'video', index)"
                    >&#xe688;</i
                  >
                  <i
                    class="iconfont c_p mr10"
                    v-show="!item.video.isSucceed && !item.video.isStop"
                    @click="stopUpload(item.video)"
                    >&#xe65b;</i
                  >
                  <i
                    class="iconfont c_p"
                    @click="cancelUpload(item.video, index, 'video', item.id)"
                    >&#xe789;</i
                  >
                </div>
              </div>
              <div
                class="resourceBox"
                v-if="item.video.isSucceed && item.resource.isFile"
              >
                <div class="uploadVideoItemName">
                  <i class="iconfont" style="color: #7a7dff">&#xef55;</i>
                  <p class="txt-over">{{ item.resource.fileOriginal }}</p>
                </div>
                <div class="uploadSpeed mr40">
                  <p class="c_33 f14">
                    {{ item.resource.isSucceed ? "上传成功" : "上传中" }}
                  </p>
                  <el-progress
                    v-show="!item.resource.isSucceed"
                    :percentage="item.resource.fileUploadSchedule"
                  ></el-progress>
                </div>
                <div class="uploadFileSet">
                  <i
                    class="iconfont c_p mr10"
                    v-show="!item.resource.isSucceed && item.resource.isStop"
                    @click="startUpload(item.resource, 'resource', index)"
                    >&#xe688;</i
                  >
                  <i
                    class="iconfont c_p mr10"
                    v-show="!item.resource.isSucceed && !item.resource.isStop"
                    @click="stopUpload(item.resource)"
                    >&#xe65b;</i
                  >
                  <i
                    class="iconfont c_p"
                    @click="cancelUpload(item.resource, index, 'resource')"
                    >&#xe789;</i
                  >
                </div>
              </div>
              <div
                v-show="item.video.isSucceed && !item.resource.isFile"
                class="addResource"
                @click="addFileDom('resourceFile' + index)"
              >
                上传该视频配套文件
              </div>
              <input
                accept=".zip,.rar,7z"
                type="file"
                :ref="`resourceFile${index}`"
                @change="addResourceFile($event, item, index)"
                style="display: none"
              />
            </div>
            <el-checkbox
              class="checkedFree"
              @change="editSectionFun(index)"
              v-model="item.isFree"
              >免费观看</el-checkbox
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { qiniuUpload } from "../../../utils/upload";
import { mapGetters } from "vuex";
import { addSection, editSection, delSection } from "../../../Api";
import { getToken } from "../../../utils/auth";

export default {
  name: "CourseMultUpload",
  props: {
    id: {
      type: Number,
    },
    sections: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      videoInfoList: [],
    };
  },
  created() {
    // this.addItem()
  },
  methods: {
    addItem() {
      return new Promise((resolve) => {
        let video = {
          observable: null,
          uploadObj: null,
          isFile: false,
          isSucceed: false,
          isStop: false,
          fileOriginal: "",
          fileName: "",
          filePath: "",
          fileUploadSchedule: 0,
          title: "",
        };
        let resource = JSON.parse(JSON.stringify(video)); // 深度拷贝
        const dataInfo = {
          id: "",
          video: video,
          resource: resource,
          isFree: true,
        };
        this.videoInfoList.push(dataInfo);
        const index = this.videoInfoList.indexOf(dataInfo);
        resolve(index);
      });
    },
    //触发添加文件
    addFileDom(ref) {
      if (ref === "videoFile") {
        this.$refs[ref].click();
      } else {
        this.$refs[ref][0].click();
      }
    },
    //添加视屏(添加节)
    addVideoFile(dataFile) {
      const file = dataFile.target.files[0];
      qiniuUpload(file, this.uploadTokenInfo.uptoken).then((res) => {
        this.addItem().then((resIndex) => {
          let observable = this.videoInfoList[resIndex];
          observable.video.observable = res;
          observable.video.fileOriginal = file.name;
          this.startUpload(observable.video, "video", resIndex);
        });
      });
    },
    //添加资源
    addResourceFile(dataFile, data, index) {
      const file = dataFile.target.files[0];
      qiniuUpload(file, this.uploadTokenInfo.uptoken).then((res) => {
        data.resource.observable = res;
        data.resource.fileOriginal = file.name;
        this.startUpload(data.resource, "resource", index);
      });
    },
    //添加节
    addSectionFun(data, index) {
      const strIndex = data.fileOriginal.lastIndexOf(".");
      const params = {
        cover: "",
        id: this.id,
        title: data.fileOriginal.substring(0, strIndex),
        token: getToken(),
        url: data.filePath,
        is_charge: 1,
      };
      this.videoInfoList[index].video.title = params.title;
      addSection(params).then((res) => {
        if (res.data.message == "Success") {
          this.videoInfoList[index].id = res.data.id;
        }
      });
    },
    //修改节
    editSectionFun(index) {
      const data = this.videoInfoList[index];
      // const strIndex = data.video.fileOriginal.lastIndexOf(".");
      const is_charge = data.isFree ? 1 : 0;
      const params = {
        id: data.id,
        title: data.video.title,
        token: getToken(),
        url: data.video.filePath,
        is_charge: is_charge,
        attachment_url: data.resource.filePath,
      };
      editSection(params).then((res) => {
        console.log(res);
      });
    },
    // 开始上传
    startUpload(data, type, SectionIndex) {
      data.isFile = true;
      data.isStop = false;
      data.uploadObj = data.observable.subscribe({
        next: (result) => {
          // 主要用来展示进度
          data.fileUploadSchedule = Math.floor(result.total.percent);
        },
        error: (errResult) => {
          // 失败报错信息
          this.$message.error("上传失败");
          console.log(errResult);
        },
        complete: (result) => {
          // 接收成功后返回的信息
          data.isSucceed = true;
          const index = result.key.lastIndexOf("."); //得到最后一个点的坐标
          data.filePath = this.uploadTokenInfo.domain + result.key;
          data.fileName = result.key.substring(0, index); //得到最后一个点之前的字符串
          if (type == "video") {
            //添加视屏时，添加节
            this.addSectionFun(data, SectionIndex);
          } else {
            // 上传资源时 为修改节操作
            this.editSectionFun(SectionIndex);
          }
        },
      });
    },
    //暂停上传
    stopUpload(data) {
      data.isStop = true;
      data.uploadObj.unsubscribe();
    },
    //删除、取消上传
    cancelUpload(data, index, type, id) {
      if (type === "video") {
        this.$confirm("此操作将永久删除该小节视频, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          delSection({ token: getToken(), id: id }).then((res) => {
            if (res.data.message == "Success")
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            // data.uploadObj.unsubscribe();
            const resetData = {
              observable: null,
              uploadObj: null,
              isFile: false,
              isSucceed: false,
              isStop: false,
              fileOriginal: "",
              fileName: "",
              filePath: "",
              fileUploadSchedule: 0,
            };
            this.$refs.videoFile.value = null
            if (type === "resource") {
              Object.assign(this.videoInfoList[index].resource, resetData);
            } else {
              this.videoInfoList.splice(index, 1);
            }
          });
        });
      } else {
        this.$confirm("此操作将永久删除该小节资源, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          const resetData = {
            observable: null,
            uploadObj: null,
            isFile: false,
            isSucceed: false,
            isStop: false,
            fileOriginal: "",
            fileName: "",
            filePath: "",
            fileUploadSchedule: 0,
          };
          if (type === "resource") {
            Object.assign(this.videoInfoList[index].resource, resetData);
          } else {
            this.videoInfoList.splice(index, 1);
          }
          const data = this.videoInfoList[index];
          const is_charge = data.isFree ? 1 : 0;
          const params = {
            id: data.id,
            title: data.video.title,
            token: getToken(),
            url: data.video.filePath,
            is_charge: is_charge,
            attachment_url: "",
          };
          editSection(params).then((res) => {
            if (res.data.message == "Success") {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              // data.uploadObj.unsubscribe();
              const resetData = {
                observable: null,
                uploadObj: null,
                isFile: false,
                isSucceed: false,
                isStop: false,
                fileOriginal: "",
                fileName: "",
                filePath: "",
                fileUploadSchedule: 0,
              };
              if (type === "resource") {
                Object.assign(this.videoInfoList[index].resource, resetData);
              } else {
                this.videoInfoList.splice(index, 1);
              }
            }
          });
        });
      }
    },
  },
  computed: {
    ...mapGetters(["uploadTokenInfo"]),
    //深拷贝
    newChapterInfo() {
      return JSON.stringify(this.chapterInfo);
    },
  },
  watch: {
    videoInfoList: {
      // 深度监听
      handler(val) {
        this.$emit("uploadFiles", val);
      },
      deep: true,
    },
    sections: {
      // 深度监听
      handler(val) {
        val.forEach((item) => {
          let video = {
            observable: null,
            uploadObj: null,
            isFile: true,
            isSucceed: true,
            isStop: false,
            fileOriginal: item.url,
            fileName: item.title,
            filePath: item.url,
            fileUploadSchedule: 0,
            title: item.title,
          };
          let resource = JSON.parse(JSON.stringify(video)); // 深度拷贝
          resource.isFile = !item.attachment_url ? false : true;
          resource.filePath = item.attachment_url;
          resource.fileOriginal = item.attachment_url;
          const dataInfo = {
            id: item.id,
            video: video,
            resource: resource,
            isFree: item.is_charge == 1 ? true : false,
          };
          this.videoInfoList.push(dataInfo);
        });
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
.checkedFree {
  margin: 0 auto;
  width: 1.8rem;
  text-align: center;
}
.addResource {
  width: 1.6rem;
  height: 0.36rem;
  line-height: 0.36rem;
  text-align: center;
  color: #f46600;
  font-size: 0.14rem;
  font-weight: bold;
  border: 0.01rem solid #f46600;
  border-radius: 0.04rem;
  margin: 0.3rem auto;
  cursor: pointer;
}
.resourceBox {
  padding: 0.1rem 0.2rem;
  display: flex;
}
.videoBox {
  background: #f7f6fb;
  padding: 0 0.2rem;
}
.uploadVideoLis {
  padding-right: 1.8rem;
}
.uploadVideoItemBox >>> .el-checkbox__label {
  font-size: 00.14rem;
}

.uploadVideoItemBox >>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #00baa2;
}

.uploadVideoItemBox >>> .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #00baa2;
  border-color: #00baa2;
}

.uploadVideoItemBox >>> .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #dcdfe6;
}

.uploadVideoItemBox >>> .el-checkbox__inner:hover {
  border-color: #00baa2;
}

.uploadFileSet {
  font-size: 0.26rem;
}

.uploadSpeed >>> .el-progress {
  margin-left: 00.1rem;
  flex: 1;
}

.uploadSpeed >>> .el-progress__text {
  font-size: 00.14rem !important;
}

.uploadSpeed >>> .el-progress-bar__inner {
  background-color: #f46600;
}

.uploadSpeed {
  display: flex;
  align-items: center;
  width: 3rem;
}

.uploadVideoItemName i {
  color: #ff9068;
  font-size: 0.2rem;
  margin-right: 0.08rem;
}

.uploadVideoItemName p {
  color: #333333;
  font-size: 0.14rem;
}

.uploadVideoItemName {
  width: 2rem;
  margin-right: 00.2rem;
  display: flex;
  align-items: center;
}

.uploadVideoItem {
  flex: 1;
  box-shadow: 0px 0px 0.03rem rgb(102 102 102 / 20%);
}

.uploadVideoItemBox {
  display: flex;
  align-items: center;
  margin-top: 00.1rem;
  box-shadow: 0px 0px 0.03rem rgb(102 102 102 / 20%);
}

.titleBox {
  display: flex;
  margin-right: 0.9rem;
  width: 3.31rem;
}
.titleBox p {
  width: 1rem;
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #040404;
  opacity: 1;
}
</style>