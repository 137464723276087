<!--素材上传表单组件-->
<template>
  <div>
    <div
      class="EditingUploadList"
      :class="{ isData: unSubmittedMaterial.length > 0 }"
    >
      <EditingUploadList
        ref="editList"
        @submitAudit="submitAudit"
        @anewEditFun="anewEditFun"
        :list="unSubmittedMaterial"
      ></EditingUploadList>
    </div>
    <div class="suForm">
      <el-form
        :model="materialForm"
        :rules="rules"
        ref="materialForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="上传图片" prop="images">
          <el-upload
            accept="image/png,image/jpg,image/jpeg"
            :class="{ imgHide: imgHideUpload }"
            drag
            class="upload-demo cover"
            list-type="picture-card"
            :multiple="true"
            :limit="5"
            :on-exceed="handleExceed"
            :on-preview="handlePictureCardPreview"
            :action="$ROOT_DIR + '/comms/Release/uploadPics?token=' + token"
            :on-remove="removeImgs"
            :file-list="loadImg"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <i class="el-icon-plus"></i>
            <div class="el-upload__text uploadTx">
              <p>请上传对应的图片</p>
              <p>支持多选上传图片</p>
            </div>
            <div
              class="el-upload__tip f12 c_bf"
              slot="tip"
              style="line-height: 0.25rem"
            >
              提示：请上传尺寸大于800像素*800像素且高宽比为1:1的JPG/JPEG/PNG格式图片，大小小于2M，默认上传第一张图为主图；
            </div>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>

        <el-form-item label="上传素材" required>
          <el-form-item>
            <el-select
              v-model="materialForm.uploadType"
              placeholder="请选择上传方式"
              @change="changeUpType"
              style="margin-bottom: 0.1rem"
            >
              <el-option label="本地文件" value="0"></el-option>
              <el-option label="网盘链接" value="1"></el-option>
            </el-select>
          </el-form-item>

          <div v-if="materialForm.uploadType == '0'" style="margin-top:0.1rem"> 
            <el-form-item class="uploadFile" prop="materialFile">
              <div>
                <el-button
                  size="small"
                  type="primary"
                  class="w400"
                  :class="{ noActive: materFileObj.isFile }"
                  @click="zh_uploadFile"
                >
                  点击上传
                </el-button>
                <input
                  accept=".zip,.rar,7z"
                  type="file"
                  ref="evfile"
                  @change="zh_uploadFile_change"
                  style="display: none"
                />
                <div class="el-upload__tip">
                  提示：附件以rar、zip、7z压缩包格式上传,文件大小需小于500M
                </div>
              </div>
              <div class="uploadFileList" v-show="materFileObj.isFile">
                <div class="uploadFileItem mt30">
                  <div class="fileName">
                    <i class="iconfont">&#xef55;</i>
                    <p class="f14 c_33 txt-over">
                      {{ materFileObj.materFile }}
                    </p>
                  </div>
                  <div class="uploadStatus" v-show="!materFileObj.isSucceed">
                    <p class="mr10">上传中</p>
                    <el-progress
                      class="statusBar"
                      :percentage="materFileObj.materFileUploadSchedule"
                      color="#F46600"
                    ></el-progress>
                  </div>
                  <div class="uploadStatus" v-show="materFileObj.isSucceed">
                    <p class="mr10">上传完成</p>
                  </div>
                  <div class="uploadSet">
                    <i
                      class="iconfont"
                      v-show="!materFileObj.isSucceed && materFileObj.isStop"
                      @click="startUpload"
                      >&#xe688;</i
                    >
                    <i
                      class="iconfont"
                      v-show="!materFileObj.isSucceed && !materFileObj.isStop"
                      @click="stopUpload"
                      >&#xe65b;</i
                    >
                    <i class="iconfont" @click="cancelUpload">&#xe789;</i>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
          <div v-else class="df_Yc w400">
            <el-form-item class="mr10" prop="materialFile" style="flex: 1">
              <el-input
                v-model="materialForm.materialFile"
                placeholder="请输入网盘链接"
              ></el-input>
            </el-form-item>
            <el-form-item prop="linkPass" style="width: 1.5rem">
              <el-input
                v-model="materialForm.linkPass"
                placeholder="请输入提取密码"
              ></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input
            class="w400"
            placeholder="请输入素材的标题"
            v-model="materialForm.title"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类" required>
          <el-col :span="7" style="width: 1.9rem" v-if="menuList.length > 0">
            <el-form-item prop="classify1">
              <el-select
                v-model="materialForm.classify1"
                placeholder="请选择分类"
                @change="selectClass_1"
              >
                <el-option
                  v-for="(item, index) in menuList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="1" class="segmentation" v-if="menuChildList.length > 0"
            >-</el-col
          >
          <el-col
            :span="7"
            style="width: 1.9rem"
            v-if="menuChildList.length > 0"
          >
            <el-form-item prop="classify2">
              <el-select
                v-model="materialForm.classify2"
                placeholder="请选择分类"
              >
                <el-option
                  v-for="(item, index) in menuChildList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="素材推荐" prop="recommendType">
          <el-radio-group v-model="materialForm.recommendType">
            <el-radio
              v-for="(item, index) in attrList"
              :key="index"
              :label="item.id"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <div class="priceTips">
          <el-form-item label="价格" prop="price">
            <el-select
              v-model="materialForm.price"
              placeholder="请选择素材价格"
              class="w400"
            >
              <el-option
                v-for="(item, index) in suAttrObj.price"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="tipsBox">
            <img class="tipImg" src="@/assets/image/icon/help.png" alt="" />
            <div class="tipsCont">
              <p>友情提示：</p>
              <p>1.根据资料库质量，设置合适的价格；</p>
              <p>2.资料库价格越低，被下载的几率越大，收益更多；</p>
            </div>
          </div>
        </div>
        <el-form-item prop="redAgreement" class="agreement">
          <el-checkbox v-model="materialForm.redAgreement">
            <p class="c_66 f12">
              我已阅读并同意<router-link
                :to="{ path: '/other/index', query: { type: 2 } }"
                target="_blank"
                ><span class="c_f46">《知设网协议》</span></router-link
              >
            </p>
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('materialForm')"
            >提交审核</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="fromBottom">
      <div>
        <div class="fromBottomTitle">
          <img src="@/assets/image/icon/zsw-icon.png" alt="" />
          <p>作品上传说明</p>
        </div>
        <div class="uploadSpecification">
          <p>1.请精准填写素材标题，准确选择分类，可提高素材被检索到的概率；</p>
          <p>
            2.上传图片建议上传3张以上，第一张为素材封面，一定要漂亮哦；像素>800*800；
          </p>
          <p>
            3.图片过小/带水印图片和模型不符者，含有广告性质模型审核不予通过；
          </p>
          <p>
            4.图片格式支持 jpg 、 png 等，素材文件为 rar 、 zip
            、7z等格式的压缩包,大小不超过500M；
          </p>
          <p>5.确保网盘链接有效，如发现网盘链接失效,所获收益将无法提现；</p>
          <p>
            6.知设网鼓励上传“原创素材”，若上传素材与本网站他人作品雷同，将作出下架处理；
          </p>
          <p>
            7,素材的意向价格为(0-9)知币；根据素材质量，知设网有权修改价格到合适区间；
          </p>
          <p>8.作品审核时间为48小时，节假日顺延;</p>
        </div>
      </div>
      <div>
        <div class="fromBottomTitle">
          <img src="@/assets/image/icon/zsw-icon.png" alt="" />
          <p>作品收益说明</p>
        </div>
        <div class="uploadSpecification">
          <p>
            1.
            “原创资料”的单独赞赏收益，其中50%归上传者所有，收益达到100元即可提现。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditingUploadList from "@/views/upload/componts/EditingUploadList";
import {
  addMaterial,
  getAttr,
  getProNavList,
  uploadMaterialImg,
  getAttributes,
  materialEdit,
  editMaterial,
} from "../../../Api";
import { getToken } from "@/utils/auth";
import { mapGetters } from "vuex";
import { qiniuUpload } from "../../../utils/upload";
import { Loading } from "element-ui";

export default {
  name: "MaterialForm",
  components: {
    EditingUploadList,
  },
  data() {
    let validateAgreement = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选阅读并同意《知设网协议》"));
      } else {
        callback();
      }
    };
    let validateUrl = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入文件链接或上传文件"));
      } else {
        const reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\\/?%&=]*)?/;
        let objExp = new RegExp(reg);
        let objExp1 = new RegExp("[\\u4E00-\\u9FFF]+","g");
        if (objExp.test(value) == true && objExp1.test(value) == false) {
          callback();
        } else {
          return callback(new Error("请输入正确文件链接格式"));
        }
      }
    };
    return {
      draftIndex: 0,
      imgHideUpload: false,
      loadImg: [],
      cover: "",
      imageFile: [],
      materialForm: {
        linkPass: "", // 网盘链接密码
        materialFileName: "", // 配套资源文件名
        materialFile: "", // 配套资源
        images: [],
        uploadType: "0", // 默认上传本地文件
        title: "",
        classify1: "",
        classify2: "",
        recommendType: "",
        price: "",
        redAgreement: false,
        editId: "",
      },
      rules: {
        linkPass: [
          { required: true, message: "请输入提取密码", trigger: "blur" },
        ],
        images: [{ required: true, message: "请上传图片", trigger: "change" }],
        materialFile: [{ validator: validateUrl, trigger: "change" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        classify1: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
        classify2: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
        recommendType: [
          { required: true, message: "请选择资料库推荐", trigger: "change" },
        ],
        redAgreement: [{ validator: validateAgreement, trigger: "change" }],
        price: [
          { required: true, message: "请选择资料库价格", trigger: "change" },
        ],
      },
      dialogImageUrl: "",
      dialogVisible: false,
      uploadObj: null,
      observable: null,
      materFileObj: {
        isStop: false,
        isSucceed: false,
        isFile: false,
        materFile: "",
        materFileUploadSchedule: 0,
      },

      attrList: [],
      menuList: [], // 菜单分类
      menuChildList: [], // 二级菜单分类
      loadingInstance: null,
      isValid: false,
      suAttrObj: [],
      eidtForm: [],
      token: "",
    };
  },
  created() {
    this.token = getToken();
    this.initData();
    getAttributes().then((res) => {
      this.suAttrObj = res.data;
    });
    //判断是否是编辑
    if (this.$route.query.id) {
      this.materialForm.editId = this.$route.query.id;
      materialEdit({ id: this.$route.query.id, token: getToken() }).then(
        (res) => {
          this.eidtForm = res.data;
          //处理图片
          this.materialForm.images = this.eidtForm.picsStr;
          this.eidtForm.picsStr.forEach((item) => {
            this.loadImg.push({ name: item, url: item });
          });
          //上传类型
          this.materialForm.uploadType = this.eidtForm.upWay;
          //标题
          this.materialForm.title = this.eidtForm.title;
          //分类
          this.materialForm.classify1 = parseInt(
            this.eidtForm.fidPath.split(",")[3]
          );
          this.materialForm.classify2 = parseInt(
            this.eidtForm.fidPath.split(",")[4]
          );
          //获取选中的分类菜单
          this.getMenu(this.materialForm.classify1);
          //价格
          this.materialForm.price = this.eidtForm.zsStr;
          //模型推荐
          this.materialForm.recommendType = this.eidtForm.yc;
          //网盘链接
          this.materialForm.suFile = this.eidtForm.filePath;
          //判断文件是网盘还是上传
          if (this.materialForm.uploadType == 0) {
            //文件
            this.materialForm.materialFile = this.eidtForm.filePath;
            this.materFileObj = {
              isStop: false,
              isSucceed: true,
              isFile: true,
              materFile: this.eidtForm.filePath,
              materFileUploadSchedule: 0,
            };
          } else {
            //网盘密码
            this.materialForm.linkPass = this.eidtForm.baiduCode;
            this.materialForm.materialFile = this.eidtForm.filePath;
          }
        }
      );
    }
  },
  methods: {
     //上传图片前的图片验证回调
    beforeAvatarUpload(file) {
      const _this = this;
      const isSize = new Promise(function (resolve, reject) {
        const img = new Image();
        const _URL = window.URL || window.webkitURl;
        img.onload = function () {
          file.width = img.width; //图片宽度
          file.height = img.height; //图片高度
          const valid = img.width >= 800 && img.height >= 800; //上传图片尺寸判定
          const valid1 = img.height / img.width == 1
          const valid2 = file.size / 1024 < 2048;
          valid && valid1 && valid2 ? resolve() : reject(new Error("error"));
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          _this.$message.error('图片 "比例或大小" 不对，请重新上传');
          return Promise.reject(new Error("error"));
        }
      );
      return isSize;
    },
    //上传成功
    handleAvatarSuccess(res) {
      if (res.data.message == "uploadSuccess") {
        this.materialForm.images.push(res.data.filePath);
      }
    },
    //初始化数据
    initData() {
      // Object.assign(this.$data, this.$options.data());
      this.getMenu(7);
      this.getAttr();
    },
    //获取栏目
    getMenu(id) {
      const data = {
        id: id,
        token: getToken(),
      };
      getProNavList(data).then((res) => {
        if (id == 7) {
          this.menuList = res.data;
        } else {
          this.menuChildList = res.data;
        }
      });
    },
    //切换上传类型
    changeUpType() {
      this.$data.materFileObj = this.$options.data().materFileObj;
      this.materialForm.materialFile = "";
      this.materialForm.linkPass = "";
    },
    //选中分类一
    selectClass_1(val) {
      this.getMenu(val);
      this.materialForm.classify2 = ""
    },
    //获取作品可选属性列表
    getAttr() {
      getAttr(getToken()).then((res) => {
        this.attrList = res.data;
      });
    },
    //点击上传资源
    zh_uploadFile() {
      if (this.materFileObj.isFile) return;
      this.$refs.evfile.click();
    },
    //获取七牛云上传对象
    zh_uploadFile_change(evfile) {
      const file = evfile.target.files[0];
      this.materFileObj.isFile = true;
      this.materFileObj.materFile = file.name;
      qiniuUpload(file, this.uploadTokenInfo.uptoken).then((res) => {
        this.observable = res;
        this.startUpload();
      });
    },

    stopUpload() {
      this.materFileObj.isStop = true;
      this.uploadObj.unsubscribe();
    },
    startUpload() {
      this.materFileObj.isStop = false;
      this.uploadObj = this.observable.subscribe({
        next: (result) => {
          // 主要用来展示进度
          this.materFileObj.materFileUploadSchedule = Math.floor(
            result.total.percent
          );
        },
        error: (errResult) => {
          // 失败报错信息
          this.$message.error("上传失败");
          console.log(errResult);
        },
        complete: (result) => {
          // 接收成功后返回的信息
          this.materialForm.materialFile =
            this.uploadTokenInfo.domain + result.key;
          const index = result.key.lastIndexOf("."); //得到最后一个点的坐标
          this.materialForm.materialFileName = result.key.substring(0, index); //得到最后一个点之前的字符串
          this.materFileObj.isSucceed = true;
        },
      });
    },
    //删除、取消上传
    cancelUpload() {
      if (this.uploadObj) this.uploadObj.unsubscribe();
      this.materFileObj = {
        isStop: false,
        isSucceed: false,
        isFile: false,
        materFile: "",
        materFileUploadSchedule: 0,
      };
      this.$data.materFileObj = this.$options.data().materFileObj;
      this.materialForm.materialFile = "";
      this.uploadObj = null;
      this.observable = null;
      this.$refs.evfile.value = null
    },

    handleExceed() {
      this.$message.warning(`最多只能上传5张图片`);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //删除图片
    removeImgs(file, fileList) {
      this.imgHideUpload = fileList.length >= 5;
      //从表单对象中删除
      this.materialForm.images = this.materialForm.images.filter(function (
        item
      ) {
        if (file.response) {
          return item != file.response.data.filePath;
        }
        if (file.url) {
          return item != file.url;
        }
      });
    },
    //上传图片列表
    uploadFiles(data) {
      return new Promise((resolve, reject) => {
        const len = data.materialForm.images.length;
        let uploadCot = 0;
        data.materialForm.images.forEach((item) => {
          let formData = new FormData();
          formData.append("file", item.raw);
          uploadMaterialImg(getToken(), formData)
            .then((res) => {
              if (res.data.message === "uploadSuccess") {
                data.imageFile.push(res.data.filePath);
                uploadCot += 1;
                if (uploadCot === len) {
                  resolve();
                }
              } else {
                console.log("上传失败");
                const message = "上传图片失败，请刷新页面重试";
                reject(message);
              }
            })
            .catch((error) => {
              console.log("错误", error);
              const message = "上传失败";
              reject(message);
            });
        });
      });
    },
    //保存数据
    saveData(data) {
      const fidPath = [
        data.materialForm.classify1,
        data.materialForm.classify2,
      ].join(",");
      let params = {
        column: 93886,
        cover: data.materialForm.images[0],
        fid: data.materialForm.classify2,
        fidPath: fidPath,
        fileName: data.materialForm.materialFileName,
        filePath: data.materialForm.materialFile,
        free: "1",
        tags: "测试,PS",
        introduce: data.materialForm.title,
        picsStr: data.materialForm.images.join(","),
        title: data.materialForm.title,
        token: getToken(),
        type: 7,
        upWay: data.materialForm.uploadType,
        yc: data.materialForm.recommendType,
        zsStr: data.materialForm.price,
      };
      if (this.materialForm.uploadType == 1) {
        params["baiduCode"] = data.materialForm.linkPass;
        delete params.fileName;
      }
      if (this.materialForm.editId) {
        params.id = this.materialForm.editId;
        editMaterial(params).then((res) => {
          if (res.data.error === 0 && res.data.message === "addSuccess") {
            this.$message.success("编辑成功！等待平台审核");
            //从草稿箱中清除
            this.$store.dispatch("upload/removeNewMaterial", this.draftIndex);
            this.$router.push({
              path: "/upload/myUpload",
              query: { activeName: "material", uploadType: 2 },
            });
          } else {
            this.$message.error("编辑失败！请刷新页面重新填写");
          }
        });
      } else {
        addMaterial(params).then((res) => {
          if (res.data.error === 0 && res.data.message === "addSuccess") {
            this.$message.success("发布成功！等待平台审核");
            //从草稿箱中清除
            this.$store.dispatch("upload/removeNewMaterial", this.draftIndex);
            this.$router.push({
              path: "/upload/myUpload",
              query: { activeName: "material", uploadType: 2 },
            });
          } else {
            this.$message.error("发布失败！请刷新页面重新填写");
          }
        });
      }
      this.loadingInstance.close();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loadingInstance = Loading.service({ fullscreen: true });
          //  上传图片
          // this.uploadFiles(this.$data)
          //   .then(() => {
          //     //保存数据
          //     this.saveData(this.$data);
          //   })
          //   .catch((error) => {
          //     this.loadingInstance.close();
          //     this.$message.error(error);
          //   });
          //保存数据
          this.saveData(this.$data);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 父组件点击添加新作品
    addNewWork() {
      return new Promise((resolve) => {
        this.$refs["materialForm"].validate((valid) => {
          if (valid) {
            this.isValid = true;
          }
        });
        let index = -1;
        if (this.$refs["editList"].hiddenItem !== -1) {
          index = this.$refs["editList"].hiddenItem;
          this.$refs["editList"].hiddenItem = -1;
        }
        const data = {
          data: this.$data,
          index: index,
        };
        this.$store.dispatch("upload/addNewMaterial", data).then(() => {
          resolve();
        });
      });
    },
    //  从新编辑
    anewEditFun(data, index) {
      this.draftIndex = index;
      data.loadImg = [];
      data.materialForm.images.forEach((item) => {
        data.loadImg.push({ url: URL.createObjectURL(item.raw) });
      });
      Object.assign(this.$data, data);
    },
    // 提交审核（子组件触发的哟）
    submitAudit(data) {
      this.loadingInstance = Loading.service({ fullscreen: true });
      //保存数据
      this.saveData(data);
      //  上传图片
      // this.uploadFiles(data)
      //   .then(() => {
      //     //保存数据
      //     this.saveData(data);
      //   })
      //   .catch((error) => {
      //     this.loadingInstance.close();
      //     this.$message.error(error);
      //   });
    },
  },
  watch: {
    // 'materialForm.images': {
    //   handler: function(val) {
    //     if(val.length > 0){
    //       console.log(val,'啦啦啦啦啦')
    //       this.cover = URL.createObjectURL(val[0].raw)
    //     }
    //
    //   },
    // }
  },
  computed: {
    ...mapGetters(["uploadTokenInfo", "unSubmittedMaterial"]),
  },
};
</script>

<style scoped>
.imgHide >>> .el-upload--picture-card {
  display: none;
}
.cover {
  line-height: 0;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.2rem;
  margin-top: 0;
}
::v-deep .el-upload--picture-card {
  width: 1.8rem;
  height: 1.8rem;
  border: none !important;
}
.tipImg:hover + .tipsCont {
  visibility: visible;
  opacity: 1;
}
.tipsCont {
  position: absolute;
  background: #666666;
  border-radius: 0.04rem;
  padding: 0.15rem;
  width: 3.16rem;
  color: #ffffff;
  font-size: 0.12rem;
  line-height: 0.2rem;
  top: 0.4rem;
  left: 0.3rem;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.tipsBox {
  position: relative;
}
.tipsBox img {
  width: 0.24rem;
  height: 0.24rem;
  margin-bottom: 0.4rem;
  margin-left: 0.3rem;
  cursor: pointer;
}
.priceTips {
  display: flex;
  align-items: center;
}

.EditingUploadList.isData {
  visibility: visible;
  opacity: 1;
  height: auto;
}

.EditingUploadList {
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}

.agreement >>> .el-form-item__content {
  line-height: 0.1rem !important;
}

.noActive {
  background: #bfbfbf !important;
  border-color: #bfbfbf !important;
}

.uploadSet {
  flex: 1;
  font-size: 0.25rem;
  text-align: right;
  color: #666666;
}

.uploadSet i {
  margin: 0 0.05rem;
  cursor: pointer;
}

.statusBar {
  flex: 1;
}

.uploadStatus {
  width: 3rem;
  display: flex;
  align-items: center;
}

.uploadFileItem {
  width: 6.56rem;
  height: 0.4rem;
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  color: #333333;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 0px 0.06rem rgba(102, 102, 102, 0.2);
  border-radius: 0.04rem;
  padding: 0 0.2rem;
}

.fileName {
  display: flex;
  align-items: center;
  /*line-height: 0.2rem;*/
  width: 2rem;
  margin-right: 0.5rem;
}

.fileName i {
  color: #7a7dff;
  font-size: 0.18rem;
  margin-right: 0.08rem;
}

.uploadSpecification {
  margin-top: 0.2rem;
  margin-left: 0.25rem;
}

.uploadSpecification p {
  color: #666666;
  font-size: 0.14rem;
  margin-bottom: 0.25rem;
}

.fromBottomTitle {
  display: flex;
  align-items: center;
}

.fromBottomTitle img {
  width: 0.16rem;
  height: 0.19rem;
  margin-right: 0.1rem;
}

.fromBottomTitle p {
  font-size: 0.16rem;
  color: #040404;
  font-weight: bold;
}

.fromBottom {
  width: 100%;
  margin-top: 0.1rem;
  margin-bottom: 0.3rem;
  padding: 0.2rem 0.3rem 0.4rem;
  background: #ffffff;
  border-radius: 0.04rem;
}

.suForm {
  width: 100%;
  margin-top: 0.1rem;
  padding: 0.2rem 0 0.4rem;
  background: #ffffff;
  border-radius: 0.04rem;
}

.segmentation {
  width: 0.2rem;
  text-align: center;
}

.suForm >>> .el-radio__label {
  font-size: 00.14rem;
}

.suForm >>> .uploadTx {
  font-size: 0.12rem;
  color: #bfbfbf;
  line-height: 0.2rem;
  margin: 0.2rem auto 0;
}

.suForm >>> .el-upload-dragger {
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  flex-direction: column;
  padding-top: 0.4rem;
  border: none !important;
  background: #f7f6fb;
}

.suForm >>> .el-form-item {
  margin-bottom: 0.4rem;
}

.suForm >>> .el-form-item .el-form-item {
  margin-bottom: 0;
}

.suForm >>> .el-radio__input.is-checked + .el-radio__label {
  color: #f46600;
}

.suForm >>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #f46600;
  background: #f46600;
}

.suForm >>> .el-button--primary {
  width: 1.55rem;
  background-color: #f46600;
  border-color: #f46600;
  font-size: 00.14rem;
}

.uploadFile >>> .el-button--primary {
  width: 4rem;
  background-color: #f46600;
  border-color: #f46600;
  font-size: 00.14rem;
  height: 0.44rem;
  line-height: 0.44rem;
  padding: 0;
}

.uploadFile >>> .el-upload__tip {
  line-height: 0.18rem;
}

.suForm >>> .el-button + .el-button {
  font-size: 0.14rem;
}

.suForm >>> .el-input {
  font-size: 0.14rem;
}

.suForm >>> .el-input__inner {
  height: 0.4rem;
  line-height: 0.4rem;
}
</style>