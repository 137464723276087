<template>
  <div>
    <div class="uploadNav" v-if="!$route.query.id">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="SU模型" name="su" lazy></el-tab-pane>
        <el-tab-pane label="3D模型" name="max" lazy></el-tab-pane>
        <el-tab-pane label="PS素材" name="ps" lazy></el-tab-pane>
        <el-tab-pane label="方案文本" name="schemeText" lazy></el-tab-pane>
        <el-tab-pane label="资料库" name="material" lazy></el-tab-pane>
        <el-tab-pane label="贴图" name="chartlet" lazy> </el-tab-pane>
        <el-tab-pane label="图库" name="photo" lazy></el-tab-pane>
        <el-tab-pane label="课程" name="course" lazy> </el-tab-pane>
      </el-tabs>
    </div>
    <div class="uploadNav" v-else>
      <el-tabs>
        <el-tab-pane label="编辑中" lazy></el-tab-pane>
      </el-tabs>
    </div>
    <div class="courseTabs" v-if="!$route.query.id && activeName == 'course'">
      <div
        :class="{ activeDiv: courseUploadType == 1 }"
        @click="changeCourseType(1)"
      >
        发布单课程
      </div>
      <div
        :class="{ activeDiv: courseUploadType == 2 }"
        @click="changeCourseType(2)"
      >
        发布章节
      </div>
    </div>
    <SuForm v-if="activeName == 'su'" ref="suForm"></SuForm>
    <!--上传模型组件-->
    <MaxForm v-if="activeName == 'max'" ref="maxForm"></MaxForm>
    <SchemeTextForm
        :key="timer"
        ref="schemeTextForm"
        v-if="activeName == 'schemeText'"
    ></SchemeTextForm>
    <MaterialForm
      :key="timer"
      ref="materialForm"
      v-if="activeName == 'material'"
    ></MaterialForm
    ><!--上传素材组件-->
    <CourseSingleForm
      v-if="activeName == 'course' && courseUploadType == 1"
    ></CourseSingleForm
    ><!--上传单节课程组件-->
    <CourseMultiForm
        :isEdit="$route.query.id ? 1 : 0"
      v-if="activeName == 'course' && courseUploadType == 2"
    ></CourseMultiForm
    ><!--上传章节课程组件-->
    <PhotoForm v-if="activeName == 'photo'"></PhotoForm>
    <PsForm v-if="activeName == 'ps'"></PsForm>
    <ChartletForm v-if="activeName == 'chartlet'"></ChartletForm>
  </div>
</template>

<script>
import SuForm from "./componts/SuForm";
import MaxForm from "./componts/MaxForm";
import MaterialForm from "./componts/MaterialForm";
import CourseSingleForm from "./componts/CourseSingleForm";
import CourseMultiForm from "./componts/CourseMultiForm";
import PhotoForm from "./componts/PhotoForm";
import PsForm from "./componts/PsForm";
import SchemeTextForm from "./componts/SchemeTextForm";
import ChartletForm from "./componts/ChartletForm";

export default {
  name: "UploadWork",
  data() {
    return {
      activeName: "",
      courseUploadType: 1,
      timer: "",
    };
  },
  components: {
    SuForm,
    MaxForm,
    MaterialForm,
    CourseSingleForm,
    CourseMultiForm,
    PhotoForm,
    PsForm,
    SchemeTextForm,
		ChartletForm
  },
  created() {
    this.activeName = this.$route.query.activeName
      ? this.$route.query.activeName
      : "su";
    if (this.$route.query.courseUploadType) {
      this.courseUploadType = this.$route.query.courseUploadType;
    }
    //将参数设置到地址栏
    const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
    query.activeName = this.activeName; //改变参数
    this.$router.push({ path: this.$route.path, query });
    this.$store.dispatch("upload/getUploadToken").then(() => {});
  },
  methods: {
    //添加新作品
    addNewWork() {
      if (this.activeName == "material") {
        this.$refs["materialForm"].addNewWork().then(() => {
          this.timer = new Date().getTime(); // 从新加载form组件
        });
      } else if(this.activeName == 'su'){
        this.$refs["suForm"].addNewWork().then(() => {
          this.timer = new Date().getTime(); // 从新加载form组件
        });
      } else if(this.activeName == 'max'){
        this.$refs["maxForm"].addNewWork().then(() => {
          this.timer = new Date().getTime(); // 从新加载form组件
        });
      }
    },
    changeCourseType(type) {
      this.courseUploadType = type;
      this.$router.push({
        path: "/upload/uploadWork",
        query: { activeName: "course" },
      });
    },
    handleClick() {
      this.$router.push({
        path: "/upload/uploadWork",
        query: { activeName: this.activeName },
      });
      // this.$store.dispatch('uploadWork/changeUploadTab',this.activeName)
    },
  },
  watch: {
    $route: {
      handler: function (val) {
        this.activeName = val.query.activeName;
      },
    },
  },
};
</script>

<style scoped>
.addNewWorkB {
  display: block;
  width: 1.55rem;
  height: 0.4rem;
  background-color: #f46600;
  border-color: #f46600;
  padding: 0;
}

.addNewWorkB .df_Yc {
  justify-content: center;
  font-size: 0.14rem;
}

.addNewWork {
  width: 1.55rem;
  height: 0.4rem;
  text-align: center;
  line-height: 0.4rem;
  background: #f46600;
  border-radius: 0.04rem;
  color: #ffffff;
  font-size: 0.14rem;
  cursor: pointer;
}

.uploadNav {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.3rem;
  border-radius: 0.06rem;
}

.uploadNav >>> .el-tabs__header {
  padding-bottom: 0.1rem;
  padding-left: 0.3rem;
  position: relative;
  margin: 0;
}

.uploadNav >>> .el-tabs__active-bar {
  background-color: #f46600;
}

.uploadNav >>> .el-tabs__item.is-active {
  color: #f46600;
}

.uploadNav >>> .el-tabs__item:hover {
  color: #f46600;
  cursor: pointer;
}

.uploadNav >>> .el-tabs__nav-wrap::after {
  height: 0;
}
.courseTabs {
  width: 11rem;
  height: 0.82rem;
  background: #ffffff;
  opacity: 1;
  border-radius: 4px;
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
}
.courseTabs div {
  width: 1.6rem;
  height: 0.42rem;
  background: #ffffff;
  border: 1px solid #999999;
  opacity: 1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666666;
  opacity: 1;
  cursor: pointer;
}
.activeDiv {
  border: 1px solid #f46600 !important;
  color: #f46600 !important;
}
.courseTabs div:nth-child(1) {
  margin-left: 0.3rem;
}
.courseTabs div:nth-child(2) {
  margin-left: 0.4rem;
}
</style>